@import "../../../../assets/styles/theme";


.itemLink {
  text-decoration: none;
  color: $black-color;
  font-weight: 600;

  &:hover {
    cursor: pointer;
  }
}

.status {
  padding: 0.75rem 1rem;
  border-radius: 2rem;
  border: solid 0.1rem $input-border-color;
  font-family: Montserrat, sans-serif;
  font-size: $small-font-size;
  font-weight: 500;
  letter-spacing: 0.36px;
  color: rgba(0, 0, 0, 0.6);
  margin-right: 0.5rem;
}

.buttonWrapper {
  display: flex;
  flex: 1;
  justify-content: space-evenly;
  flex-wrap: wrap;
  align-items: center;
}

.aHref {
  display: inline-flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;

  &:hover {
    font-weight: bold;
    color: #0da3e4;
    cursor: pointer;
  }

  &::after{
    content: attr(data-text);
    height: 0;
    visibility: hidden;
    font-weight: bold;
  }

}