@import "variables";
@import "mixins";
@import "../theme-variables";

.month-calendar {
  min-height: 30rem;
  .react-datepicker-wrapper {
    display: inline-block;
    padding: 0;
    border: 0;
  }

  .react-datepicker {
    font-family: $datepicker__font-family;
    font-size: $datepicker__font-size;
    background-color: $white-color;
    color: $datepicker__text-color;
    border: none;
    display: inline-block;
    position: relative;
    width: 100%;

    .react-datepicker__navigation {
      top: -0.7rem;
    }
  }

  .react-datepicker--time-only {
    .react-datepicker__triangle {
      left: 35px;
    }

    .react-datepicker__time-container {
      border-left: 0;
    }

    .react-datepicker__time {
      border-radius: $datepicker__border-radius;
    }

    .react-datepicker__time-box {
      border-radius: $datepicker__border-radius;
    }
  }

  .react-datepicker__current-month,
  .react-datepicker-time__header,
  .react-datepicker-year-header {
    display: flex;
    justify-content: center;
    font-size: $base-font-size;
    font-weight: 500;
    margin-bottom: 2rem;
  }

  //.react-datepicker__navigation {
  //  height: 4rem;
  //  width: 4rem;
  //  text-align: center;
  //  cursor: pointer;
  //  position: absolute;
  //  top: 0;
  //  padding: 0;
  //  border: none;
  //  z-index: 1;
  //  text-indent: -999em;
  //  overflow: hidden;
  //
  //  &--previous {
  //    left: 20%;
  //    background: url("../../images/arrow-left.png") no-repeat center;
  //
  //    &:hover {
  //      border-right-color: unset;
  //      background: #ebebeb url("../../images/arrow-left.png") no-repeat center;
  //    }
  //
  //    &--disabled,
  //    &--disabled:hover {
  //      border-right-color: unset;
  //      cursor: default;
  //    }
  //  }
  //
  //  &--next {
  //    right: 20%;
  //    background: url("../../images/arrow-right.png") no-repeat center;
  //    // border-left-color: $datepicker__muted-color;
  //    &--with-time:not(&--with-today-button) {
  //      right: 80px;
  //    }
  //
  //    &:hover {
  //      border-left-color: unset;
  //      background: #ebebeb url("../../images/arrow-right.png") no-repeat center;
  //    }
  //
  //    &--disabled,
  //    &--disabled:hover {
  //      border-left-color: unset;
  //      cursor: default;
  //    }
  //  }
  //
  //  &--years {
  //    position: relative;
  //    top: 0;
  //    display: block;
  //    margin-left: auto;
  //    margin-right: auto;
  //
  //    &-previous {
  //      top: 4px;
  //      border-top-color: $datepicker__muted-color;
  //
  //      &:hover {
  //        border-top-color: darken($datepicker__muted-color, 10%);
  //      }
  //    }
  //
  //    &-upcoming {
  //      top: -4px;
  //      border-bottom-color: $datepicker__muted-color;
  //
  //      &:hover {
  //        border-bottom-color: darken($datepicker__muted-color, 10%);
  //      }
  //    }
  //  }
  //
  //  &:hover {
  //    background-color: $primary-color;
  //  }
  //}

  .react-datepicker__day {
    font-size: $base-font-size;
    line-height: 5rem;
    display: flex;
    height: 3rem;
    min-height: 3rem;
    min-width: 3rem;
    width: 3rem;
    margin: 1rem;
    justify-content: center;
    align-items: center;
    outline: none;

    &.available, &.disabled {
      color: $black-color;
      background-color: rgba($secondary-color, 0.1);
      font-weight: 600;
      border-radius: $datepicker__border-radius;

      &.react-datepicker__day--selected {
        color: $white-color;
        background-color: $secondary-color;
        outline: none;
      }

      &.react-datepicker__day--disabled {
        color: #ccc;;
        background-color: transparent;
        font-weight: 400;
      }
    }

    &.disabled {
      opacity: 0.5;
      cursor: not-allowed;
      pointer-events: none;
    }

    &.react-datepicker__day--outside-month {
      color: #ccc;
    }
  }


  .react-datepicker__portal {
    .react-datepicker__current-month,
    .react-datepicker-time__header {
      font-size: $datepicker__font-size * 1.8;
    }

    .react-datepicker__navigation {
      border: none;
    }
  }

  &.event-calendar {
    display: flex;
    justify-content: flex-start;
    align-items: center;

    .react-datepicker__day {
      min-height: 3rem;
      min-width: 3rem;
      margin: 0.2rem;
      line-height: unset;
      justify-content: center;
      align-items: center;
      padding: 0.4rem;
      flex-direction: column;

      &.react-datepicker__day--selected {
        color: $white-color;
      }
    }

    .react-datepicker__month {
      margin: 0;

      .react-datepicker__week {
        .react-datepicker__day--keyboard-selected,
        .react-datepicker__month-text--keyboard-selected,
        .react-datepicker__quarter-text--keyboard-selected {
          border-radius: $datepicker__border-radius;
          background-color: $white-color;
          color: $datepicker__text-color;
        }

        .react-datepicker__day--selected,
        .react-datepicker__day--selected.react-datepicker__day--today {
          background-color: $secondary-color;
          border-radius: $datepicker__border-radius;
          color: $white-color;
          box-shadow: -6px 8px 27px 0 rgba(0, 0, 0, 0.1);

          &:hover {
            opacity: 0.5;
          }
        }

        .react-datepicker__day--today {
          background-color: rgba(0, 0, 0, 0.05);
          border-radius: $datepicker__border-radius;
          color: $datepicker__text-color;
        }
      }
    }

    .react-datepicker__day {
      position: relative;

      &.react-datepicker__day--disabled {
        color: #ccc;;
        background-color: transparent;
        font-weight: 400;
      }
    }

    & > div {
      width: 100%;
      height: 100%;
    }
  }
}
