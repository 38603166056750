.dropdown {
  .dropdown-toggle {
    display: inline-block;
    padding-right: 1.5rem;
    color: $navigation-menu-color;

    &:after {
      display: none;
    }

    &:hover {
      text-decoration: none;
    }
  }
}
