.event-container {
  border-top: 0.2rem $events-divider-color solid;
  padding: 2.9rem 0 0.6rem;
  margin-top: 1.5rem;

  .no-events {
    font-weight: 600;
  }

  .event-details {
    margin-bottom: 2.9rem;

    .date {
      display: flex;
      align-items: center;
      margin-bottom: 1.3rem;

      p {
        font-family: Montserrat, sans-serif;
        font-size: $xs-font-size;
        font-weight: 500;
        line-height: 1.38;
        letter-spacing: 0.1rem;
        color: $black-color;
      }

      span {
        width: 0.5rem;
        height: 0.5rem;
        background-color: $datepicker-header-color;
        margin: 0 0.8rem;
        border-radius: 50%;
      }
    }

    .details {
      font-family: Montserrat, sans-serif;
      font-size: $base-m-font-size;
      font-weight: 600;
      color: $black-color;
      margin-bottom: 2rem;
      .event-name {
          background-color: transparent;
      }

      &:hover {
        color: $info-color;
        cursor: pointer;
      }
    }

    .applicant-data-button {
      border-radius: $border-radius;
      box-shadow: -0.6rem 0.8rem 2.7rem 0 rgba(0, 0, 0, 0.1);
    }

    &:last-of-type {
      margin-bottom: 0;
    }
  }
}
