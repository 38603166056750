@import 'variables';
@import 'mixins';

.react-datepicker-host {
  position: relative;

  .react-datepicker-wrapper {
    .react-datepicker__input-container {
      position: relative;

      &:after {
        content: '';
        position: absolute;
        top: calc(50% - 1rem);
        right: 1.5rem;
        width: 2rem;
        height: 2rem;
        background: url("data:image/svg+xml,%3C%3Fxml version='1.0' encoding='utf-8'%3F%3E%3Csvg version='1.1' id='Layer_1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' viewBox='0 0 512 512' style='enable-background:new 0 0 512 512;' xml:space='preserve' fill='%23888'%3E%3Cg%3E%3Cpath d='M102.4,76.8v25.6h307.2c14.1,0,25.6,11.5,25.6,25.6v307.2c0,14.1-11.5,25.6-25.6,25.6H102.4c-14.1,0-25.6-11.5-25.6-25.6 V128c0-14.1,11.5-25.6,25.6-25.6V76.8V51.2C60,51.2,25.6,85.6,25.6,128v307.2c0,42.4,34.4,76.8,76.8,76.8h307.2 c42.4,0,76.8-34.4,76.8-76.8V128c0-42.4-34.4-76.8-76.8-76.8H102.4V76.8z'/%3E%3Cpath d='M332.8,25.6V128c0,14.1,11.5,25.6,25.6,25.6S384,142.1,384,128V25.6C384,11.5,372.5,0,358.4,0S332.8,11.5,332.8,25.6'/%3E%3Cpath d='M128,25.6V128c0,14.1,11.5,25.6,25.6,25.6s25.6-11.5,25.6-25.6V25.6c0-14.1-11.5-25.6-25.6-25.6S128,11.5,128,25.6'/%3E%3Cpath d='M51.2,256h409.6c14.1,0,25.6-11.5,25.6-25.6c0-14.1-11.5-25.6-25.6-25.6H51.2c-14.1,0-25.6,11.5-25.6,25.6 C25.6,244.5,37.1,256,51.2,256'/%3E%3Cpath d='M153.6,332.8v25.6h25.6v-25.6H153.6v25.6V332.8H128V384c0,6.7,2.7,13.3,7.5,18.1c4.8,4.8,11.4,7.5,18.1,7.5h51.2 c6.7,0,13.3-2.7,18.1-7.5c4.8-4.8,7.5-11.4,7.5-18.1v-51.2c0-6.7-2.7-13.3-7.5-18.1c-4.8-4.8-11.4-7.5-18.1-7.5h-51.2 c-6.7,0-13.3,2.7-18.1,7.5c-4.8,4.8-7.5,11.4-7.5,18.1H153.6z'/%3E%3C/g%3E%3C/svg%3E") no-repeat center;
      }

      input {
        cursor: pointer;
        height: 5rem;
        border-radius: .4rem;
        font-weight: 500;
        font-size: $base-font-size;
        padding-left: 2rem;
        border: 0.2rem solid $border-color;
        color: $input-text-color;

        &:hover,
        &:focus {
          box-shadow: none;
        }
      }
    }
  }

  .react-datepicker__tab-loop {
    position: absolute;
    top: 0;
    right: 0;
    margin-top: 0;
    z-index: 9999;

    .react-datepicker-popper {
      position: relative !important;
      // transform: none !important;

      .react-datepicker {
        border-radius: $border-radius;
        box-shadow: 0 0 2rem 0 rgba(0, 0, 0, .2);
        overflow: hidden;
        border-color: $input-border-color;
        background: $white-color;

        .react-datepicker__header {
          padding: 1rem 1rem 0;

          .react-datepicker__current-month {
            display: flex;
            align-items: center;
            justify-content: center;
            height: 5rem;
            font-size: $base-font-size;
            color: $gray-color;
            font-weight: 700;
          }

          .react-datepicker__header__dropdown--select {
            display: flex;
            justify-content: center;
          }
        }

        .react-datepicker__month {
          .react-datepicker__week {
            .react-datepicker__day {
              &.react-datepicker__day--selected,
              &.react-datepicker__day--keyboard-selected {
                background: $primary-color;
                box-shadow: 0.68rem 0.73rem 2.7rem 0 rgba($black-color, 0.1);
                font-weight: 600;
              }

              &.react-datepicker__day--outside-month {
                opacity: 0.4;
              }
            }
          }
        }
      }
    }
  }

  .react-datepicker__navigation {
    width: 3rem;
    height: 3rem;
    max-height: 3rem;
    text-align: center;
    cursor: pointer;
    position: absolute;
    top: 2rem;
    padding: 0;
    z-index: 1;
    text-indent: -999em;
    overflow: hidden;

    &--previous {
      left: 5%;
      background: url("data:image/svg+xml,%3Csvg aria-hidden='true' focusable='false' data-prefix='fas' data-icon='chevron-left' class='svg-inline--fa fa-chevron-left fa-w-10' role='img' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 320 512'%3E%3Cpath fill='%23888' d='M34.52 239.03L228.87 44.69c9.37-9.37 24.57-9.37 33.94 0l22.67 22.67c9.36 9.36 9.37 24.52.04 33.9L131.49 256l154.02 154.75c9.34 9.38 9.32 24.54-.04 33.9l-22.67 22.67c-9.37 9.37-24.57 9.37-33.94 0L34.52 272.97c-9.37-9.37-9.37-24.57 0-33.94z'%3E%3C/path%3E%3C/svg%3E") no-repeat center;
      background-size: 25%;

      &--disabled,
      &--disabled:hover {
        border-right-color: $datepicker__navigation-disabled-color;
        cursor: default;
      }
    }

    &--next {
      right: 5%;

      background: url("data:image/svg+xml,%3Csvg aria-hidden='true' focusable='false' data-prefix='fas' data-icon='chevron-right' class='svg-inline--fa fa-chevron-right fa-w-10' role='img' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 320 512'%3E%3Cpath fill='%23888' d='M285.476 272.971L91.132 467.314c-9.373 9.373-24.569 9.373-33.941 0l-22.667-22.667c-9.357-9.357-9.375-24.522-.04-33.901L188.505 256 34.484 101.255c-9.335-9.379-9.317-24.544.04-33.901l22.667-22.667c9.373-9.373 24.569-9.373 33.941 0L285.475 239.03c9.373 9.372 9.373 24.568.001 33.941z'%3E%3C/path%3E%3C/svg%3E") no-repeat center;
      background-size: 25%;


      &--with-time:not(&--with-today-button) {
        right: 80px;
      }

      &--disabled,
      &--disabled:hover {
        border-left-color: $datepicker__navigation-disabled-color;
        cursor: default;
      }
    }

    &--years {
      position: relative;
      top: 0;
      display: block;
      margin-left: auto;
      margin-right: auto;

      &-previous {
        top: 4px;
        border-top-color: $datepicker__muted-color;

        &:hover {
          border-top-color: darken($datepicker__muted-color, 10%);
        }
      }

      &-upcoming {
        top: -4px;
        border-bottom-color: $datepicker__muted-color;

        &:hover {
          border-bottom-color: darken($datepicker__muted-color, 10%);
        }
      }
    }

    &:hover {
      background-color: #ebebeb;
      border-radius: $datepicker__border-radius;
    }
  }
}

.react-datepicker__year-dropdown-container--select,
.react-datepicker__month-dropdown-container--select,
.react-datepicker__month-year-dropdown-container--select,
.react-datepicker__year-dropdown-container--scroll,
.react-datepicker__month-dropdown-container--scroll,
.react-datepicker__month-year-dropdown-container--scroll {
  display: inline-block;
  margin: 0 2px;
}

.react-datepicker-time__header {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.react-datepicker__month-container {
  float: left;
  width: 100%;
}

.react-datepicker__year {
  margin: $datepicker__margin;
  text-align: center;

  &-wrapper {
    display: flex;
    flex-wrap: wrap;
    max-width: 180px;
  }

  .react-datepicker__year-text {
    display: inline-block;
    width: 4rem;
    margin: 2px;
  }
}

.react-datepicker__month {
  background-color: $white-color;
  text-align: center;
  width: 100%;

  .react-datepicker__month-text,
  .react-datepicker__quarter-text {
    display: inline-block;
    width: 4rem;
    margin: 2px;
  }
}

.react-datepicker__input-time-container {
  clear: both;
  width: 100%;
  float: left;
  margin: 5px 0 10px 15px;
  text-align: left;

  .react-datepicker-time__caption {
    display: inline-block;
  }

  .react-datepicker-time__input-container {
    display: inline-block;

    .react-datepicker-time__input {
      display: inline-block;
      margin-left: 10px;

      input {
        width: 85px;
      }

      input[type="time"]::-webkit-inner-spin-button,
      input[type="time"]::-webkit-outer-spin-button {
        -webkit-appearance: none;
        margin: 0;
      }

      input[type="time"] {
        -moz-appearance: textfield;
      }
    }

    .react-datepicker-time__delimiter {
      margin-left: 5px;
      display: inline-block;
    }
  }
}

.react-datepicker__time-container {
  float: right;
  border-left: 1px solid $datepicker__border-color;
  width: 85px;

  &--with-today-button {
    display: inline;
    border: 1px solid #aeaeae;
    border-radius: $datepicker__border-radius;
    position: absolute;
    right: -72px;
    top: 0;
  }

  .react-datepicker__time {
    position: relative;
    background: white;
    border-bottom-right-radius: 0.3rem;

    .react-datepicker__time-box {
      width: 85px;
      overflow-x: hidden;
      margin: 0 auto;
      text-align: center;

      ul.react-datepicker__time-list {
        list-style: none;
        margin: 0;
        height: calc(195px + (#{$datepicker__item-size} / 2));
        overflow-y: scroll;
        padding-right: 0;
        padding-left: 0;
        width: 100%;
        box-sizing: content-box;

        li.react-datepicker__time-list-item {
          height: 30px;
          padding: 5px 10px;
          white-space: nowrap;

          &:hover {
            cursor: pointer;
            background-color: $datepicker__background-color;
          }

          &--selected {
            background-color: $datepicker__selected-color;
            color: white;
            font-weight: bold;

            &:hover {
              background-color: $datepicker__selected-color;
            }
          }

          &--disabled {
            color: $datepicker__muted-color;

            &:hover {
              cursor: default;
              background-color: transparent;
            }
          }
        }
      }
    }
  }
}

.react-datepicker__week-number {
  color: $datepicker__muted-color;
  display: inline-block;
  width: $datepicker__item-size;
  line-height: $datepicker__item-size;
  text-align: center;
  margin: $datepicker__day-margin;

  &.react-datepicker__week-number--clickable {
    cursor: pointer;

    &:hover {
      border-radius: $datepicker__border-radius;
      background-color: $datepicker__background-color;
    }
  }
}

.react-datepicker__day-names,
.react-datepicker__week {
  white-space: nowrap;
}

.react-datepicker__week {
  display: flex;
  justify-content: space-around;
}

.react-datepicker__day-names {
  display: flex;
  height: 4rem;
  margin-top: 1rem;
  background-color: $datepicker__background-color;

  .react-datepicker__day-name {
    color: $datepicker-header-color;
    // width: 5rem;
    display: flex;
    justify-content: center;
    align-items: center;
    flex: 1;
    line-height: 2rem;
    margin: 0.9rem 0;
    // border-right: 0.1rem solid $border-color;
    font-weight: 600;
    font-size: $small-font-size;
    font-family: Montserrat, sans-serif;

    &:last-of-type {
      border-right: none;
    }
  }
}

.react-datepicker__day-name,
.react-datepicker__day,
.react-datepicker__time-name {
  color: $datepicker__text-color;
  display: inline-block;
  width: $datepicker__item-size;
  line-height: $datepicker__item-size;
  text-align: center;
  margin: $datepicker__day-margin;
}

.react-datepicker__month,
.react-datepicker__quarter {
  &--selected,
  &--in-selecting-range,
  &--in-range {
    border-radius: $datepicker__border-radius;
    background-color: $datepicker__selected-color;
    color: #fff;
    font-weight: bold;

    &:hover {
      background-color: darken($datepicker__selected-color, 5%);
    }
  }

  &--disabled {
    color: $datepicker__muted-color;
    pointer-events: none;

    &:hover {
      cursor: default;
      background-color: transparent;
    }
  }
}

.react-datepicker__day {
  font-size: $base-font-size;
  line-height: 5rem;
  // width: 5rem;
  display: flex;
  // flex: 1;
  height: 3rem;
  width: 3rem;
  margin: 1rem;
  justify-content: center;
  align-items: center;
  outline: none;

  &.available, &.disabled {
    color: #35E4A1;
    background-color: #E0F7EF;
    font-weight: 600;
    border-radius: $datepicker__border-radius;

    &.react-datepicker__day--selected {
      color: $white-color;
      background-color: $primary-color;
      outline: none;
    }

    &.react-datepicker__day--disabled {
      color: #ccc;;
      background-color: transparent;
      font-weight: 400;
    }
  }

  &.disabled {
    opacity: 0.5;
    cursor: not-allowed;
    pointer-events: none;
  }
}

.react-datepicker__day,
.react-datepicker__month-text,
.react-datepicker__quarter-text,
.react-datepicker__year-text {
  cursor: pointer;

  &:hover {
    border-radius: $datepicker__border-radius;
    background-color: $datepicker__background-color;
  }

  //&--today {
  //  font-weight: bold;
  //}

  &--selected,
  &--in-selecting-range,
  &--in-range {
    border-radius: $datepicker__border-radius;
    background-color: $primary-color;
    color: #fff;
    font-weight: bold;

    &:hover {
      background-color: darken($primary-color, 5%);
    }
  }

  &--keyboard-selected {
    border-radius: $datepicker__border-radius;
    background-color: $primary-color;
    color: #fff;
    font-weight: 600;

    &:hover {
      background-color: darken($primary-color, 5%);
    }
  }

  &--in-selecting-range:not(&--in-range) {
    background-color: rgba($primary-color, 0.5);
  }

  &--in-range:not(&--in-selecting-range) {
    .react-datepicker__month--selecting-range & {
      background-color: $datepicker__background-color;
      color: $datepicker__text-color;
    }
  }

  &--disabled {
    cursor: default;
    color: $datepicker__muted-color;

    &:hover {
      background-color: transparent;
    }
  }
}

.react-datepicker__month-text,
.react-datepicker__quarter-text {
  &.react-datepicker__month--selected,
  &.react-datepicker__month--in-range,
  &.react-datepicker__quarter--selected,
  &.react-datepicker__quarter--in-range {
    &:hover {
      background-color: $primary-color;
    }
  }

  &:hover {
    background-color: $datepicker__background-color;
  }
}

.react-datepicker__year-read-view,
.react-datepicker__month-read-view,
.react-datepicker__month-year-read-view {
  border: 1px solid transparent;
  border-radius: $datepicker__border-radius;

  &:hover {
    cursor: pointer;

    .react-datepicker__year-read-view--down-arrow,
    .react-datepicker__month-read-view--down-arrow {
      border-top-color: darken($datepicker__muted-color, 10%);
    }
  }

  &--down-arrow {
    @extend %triangle-arrow-down;
    border-top-color: $datepicker__muted-color;
    float: right;
    margin-left: 20px;
    top: 8px;
    position: relative;
    border-width: $datepicker__navigation-size;
  }
}

.react-datepicker__year-dropdown,
.react-datepicker__month-dropdown,
.react-datepicker__month-year-dropdown {
  background-color: $datepicker__background-color;
  position: absolute;
  width: 50%;
  left: 25%;
  top: 30px;
  z-index: 1;
  text-align: center;
  border-radius: $datepicker__border-radius;
  border: 1px solid $datepicker__border-color;

  &:hover {
    cursor: pointer;
  }

  &--scrollable {
    height: 150px;
    overflow-y: scroll;
  }
}

.react-datepicker__year-option,
.react-datepicker__month-option,
.react-datepicker__month-year-option {
  line-height: 20px;
  width: 100%;
  display: block;
  margin-left: auto;
  margin-right: auto;

  &:first-of-type {
    border-top-left-radius: $datepicker__border-radius;
    border-top-right-radius: $datepicker__border-radius;
  }

  &:last-of-type {
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    border-bottom-left-radius: $datepicker__border-radius;
    border-bottom-right-radius: $datepicker__border-radius;
  }

  &:hover {
    background-color: $datepicker__muted-color;

    .react-datepicker__navigation--years-upcoming {
      border-bottom-color: darken($datepicker__muted-color, 10%);
    }

    .react-datepicker__navigation--years-previous {
      border-top-color: darken($datepicker__muted-color, 10%);
    }
  }

  &--selected {
    position: absolute;
    left: 15px;
  }
}

.react-datepicker__close-icon {
  cursor: pointer;
  background-color: transparent;
  border: 0;
  outline: 0;
  padding: 0 6px 0 0;
  position: absolute;
  top: 0;
  right: 0;
  height: 100%;
  display: table-cell;
  vertical-align: middle;

  &::after {
    cursor: pointer;
    background-color: $datepicker__selected-color;
    color: #fff;
    border-radius: $datepicker__border-radius;
    height: 16px;
    width: 16px;
    padding: 2px;
    font-size: 12px;
    line-height: 1;
    text-align: center;
    display: table-cell;
    vertical-align: middle;
    content: "\00d7";
  }
}

.react-datepicker__today-button {
  background: $datepicker__background-color;
  border-top: 1px solid $datepicker__border-color;
  cursor: pointer;
  text-align: center;
  font-weight: bold;
  padding: 5px 0;
  clear: left;
}

.react-datepicker__portal {
  position: fixed;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.8);
  left: 0;
  top: 0;
  justify-content: center;
  align-items: center;
  display: flex;
  z-index: 2147483647;

  .react-datepicker__day-name,
  .react-datepicker__day,
  .react-datepicker__time-name {
    width: 3rem;
    line-height: 3rem;
  }

  // Resize for small screens
  @media (max-width: 400px), (max-height: 550px) {
    .react-datepicker__day-name,
    .react-datepicker__day,
    .react-datepicker__time-name {
      width: 2rem;
      line-height: 2rem;
    }
  }

  .react-datepicker__current-month,
  .react-datepicker-time__header {
    font-size: $datepicker__font-size * 1.8;
  }

  .react-datepicker__navigation {
    border: 1.8 * $datepicker__navigation-size solid transparent;
  }

  .react-datepicker__navigation--previous {
    border-right-color: $datepicker__muted-color;

    &:hover {
      border-right-color: darken($datepicker__muted-color, 10%);
    }

    &--disabled,
    &--disabled:hover {
      border-right-color: $datepicker__navigation-disabled-color;
      cursor: default;
    }
  }

  .react-datepicker__navigation--next {
    border-left-color: $datepicker__muted-color;

    &:hover {
      border-left-color: darken($datepicker__muted-color, 10%);
    }

    &--disabled,
    &--disabled:hover {
      border-left-color: $datepicker__navigation-disabled-color;
      cursor: default;
    }
  }
}
