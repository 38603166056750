table.data-table {
  border-collapse: collapse;
  width: 100%;

  &.default-table {
    letter-spacing: 0;

    &.operation-history {
        thead {
            th {
                font-size: 1.4rem;
            }
        }
        tbody {
            td {
                padding: 2.4rem 1.6rem;
                font-size: $small-font-size;
            }
        }
    }

    .text-center {
      text-align: center;
    }

    .package-row {
        background-color: $base-background;
        .highlight-cell, .no-padding {
            background-color: $base-background;
        }
    }

    .highlight-cell {
      background-color: $file-uploader-background;

      .main-information {
        display: flex;
        align-items: center;
      }

      &.schedule-cell {
        color: rgba(0, 0, 0, 0.6);
      }

      &.schedule-event-name {
        font-weight: 600;
      }

      &.custom-cell {
        vertical-align: baseline;
      }
    }

    thead {
      th {
        font-size: $base-m-font-size;
        padding: 2.4rem;
        border-bottom: 0.1rem solid $table-bottom-border-color;

        &:not(:first-child) {
          border-left: 0.1rem solid $table-bottom-border-color;
        }

        &.greyed {
          color: rgba($black-color, 0.6);
          font-weight: 600;
        }
        &.blued {
          background-color: rgba($primary-color, 1.0);
          color: white;
          font-weight: 600;
        }
        &.index-column {
            padding: 0 0.5rem;
            background-color: $white-color;
            min-width: unset;
        }
      }
    }

    tbody {
      tr {
        &:nth-child(2n),
        &:hover,
        &:not(.package-row) {
          background: none;
        }
        &.package-row {
            background-color: $base-background;
        }
        border-bottom: 0.1rem dashed $table-bottom-border-color;
      }

      td {
        font-weight: 500;
        color: $black-color;
        padding: 1.2rem 2.4rem;
        border-right: 0.1rem solid $table-bottom-border-color;
        &:last-child {
            border-right: 0;
        }

        &.blued {
          background-color: rgba($primary-color, 1.0);
          color: white;
          font-weight: 600;
        }

        a {
          &:hover {
            text-decoration: none;
            color: $theme-color;
          }
        }

        &.no-padding {
            padding: 0 0.5rem;
            min-width: unset;
            background-color: $white-color;
        }

        &.borderless {
          border: none;
        }
      }
    }
  }

  &.no-hover {
    tbody {
      tr {
        &:hover {
          &:nth-child(2n) {
            background: $table-row-background-color;
          }

          &:nth-child(2n + 1) {
            background: initial;
          }
        }
      }
    }
  }

  thead {
    tr {
      height: 5.5rem;
      background-color: $calendar-tabs-background;

      th {
        padding: 1rem;
        min-width: 8rem;
        font-weight: 600;
        font-size: $base-font-size;
        color: $view-title-color;
        vertical-align: middle;
      }
    }
  }

  tbody {
    tr {
      &:nth-child(odd),
      &:hover,
      &:not(.package-row) {
        background: $file-uploader-background;
      }

      td {
        vertical-align: middle;
        padding: 1rem;
        font-family: Montserrat, sans-serif;
        font-size: $base-font-size;
        letter-spacing: 0.45px;
        color: $black-color;
        font-weight: 500;

        a {
          &:hover {
            text-decoration: none;
            font-weight: 500;
            color: $theme-color;
          }
        }

        &.data-table-title {
          font-weight: bold;
        }

        &.data-table-highlight {
          background-color: $light-color;
        }
      }
    }
  }

  &.default-table {
    tbody {
      tr {
        &:nth-child(odd),
        &:hover {
            &.package-row {
                background: $base-background;
            }
          background: $white-color;
        }
      }
    }
  }
}

table.marketplace-table {
  font-family: Montserrat, sans-serif;
  font-size: $base-font-size;
  width:100%;
  table-layout: fixed;

  tbody {
    tr {
      th {
        padding: 1rem;

        .th-content {
          font-family: Montserrat, sans-serif;
          font-size: $base-font-size;
          font-weight: 500;
          line-height: 1.57;
          color: $black-color;
          letter-spacing: normal;

          i {
            color: $info-color;
            margin-right: 0.5rem;
            font-weight: 600;
          }

          img {
            height: 1.6rem;
            width: 1.6rem;
            margin-right: 0.5rem;
          }

          &.with-icon {
            display: flex;
            justify-items: center;
            align-items: center;

            img {
              height: 2.2rem;
              width: 2.2rem;
              margin: 1.2rem 1.7rem 1.2rem 1.2rem;
            }
          }
        }

        &.application-element {
          display: flex;
          align-items: center;

          img {
            height: 2rem;
            width: 2rem;
            margin: 0 2rem 0 1rem;
          }

          i {
            font-size: $ml-font-size;
            color: $info-color;
            margin: 0 2rem 0 1rem;
          }
        }
      }

      td {
        text-align: center;
        vertical-align: middle;
        padding: 0.5rem 1rem;

        &.application-select {
          div {
            display: flex;
            justify-content: center;
            align-items: center;
          }
        }

        i {
          font-size: $base-m-font-size;
          color: $info-color;
          font-weight: 700;

          &.icon-x {
            color: $btn-action-color;
          }
        }

        &.header-td {
          font-weight: 600;
          font-size: $base-m-font-size;
        }

        &:nth-child(3) {
          border-left: 0.3rem solid $info-color;
          border-right: 0.3rem solid $info-color;

          &.header-td {
            border-radius: 0.4rem 0.4rem 0 0;
            border-top: 0.3rem solid $primary-color;
          }

          &.application-select {
            border-bottom: 0.3rem solid $primary-color;
          }
        }
      }
    }

    tr:nth-child(even) {
      background-color: rgba($secondary-color, 0.05);
    }
  }
}

table {
  .default-status-badge {
    display: flex;
    justify-content: center;
    padding: 1rem 1rem;
    border-radius: 2rem;
    background-color: $white-color;
    color: rgba($black-color, 0.6);
    margin: 0 0.25rem 0.25rem 0;
    font-weight: 500;
    border: 0.2rem solid $input-border-color;
    width: 100%;
  }

  .status-type-cell {
    position: relative;
    .status-select-menu {
      position: absolute;
      list-style: none;
      padding: 1rem;
      border: thin solid $border-color;
      z-index: 100;
      text-align: center;
      left: 0;
      right: 0;
      margin-top: 0.5rem;
      border-radius: 0.4rem;
      background-color: $white-color;
      box-shadow: -0.6rem 0.8rem 2.7rem 0 rgba(0, 0, 0, 0.1);

      .status-select-item {
        margin: 0.5rem 0;
        .application-status-badge {
          &:hover {
            background-color: $info-color;
            color: $white-color;
            border-color: transparent;
            cursor: pointer;
          }
        }
      }
    }
  }
}
