@import "~bootstrap/scss/bootstrap";
@import "font/feather";

@import "~educat-styles/normalize";
@import "~educat-styles/panel/theme-variables";
@import "~educat-styles/panel/style";
@import "~educat-styles/panel/form-controls/style";
@import "menu-icons";
@import "badge";
@import "edit-inquiry-form";

.dashboard-view {
  .main-container-wrapper {
    .main-container {
      overflow: hidden;

      .content-wrapper {
        .content {
          overflow: hidden;

          .actions-container {
            display: flex;
            gap: 1.2rem;
          }

          .table-container {
            overflow: auto;

            table {
              thead th,
              tbody td {
                font-size: 1.2rem;
                padding: 0.6rem 1.2rem;

                &:first-child {
                  max-width: 2rem;
                }

                .inquiry-actions-container {
                  list-style: none;

                  a {
                    font-size: 1.2rem;
                    line-height: 1.75;
                    cursor: pointer;
                    text-decoration: none;
                    color: $black;

                    &:hover {
                      color: $primary-color;
                    }

                    &.disabled {
                      opacity: 0.4;
                      pointer-events: none;
                      cursor: default;
                    }
                  }
                }
              }
              tbody tr {
                &:nth-child(even) {
                  background: rgba(0,0,0,0.03);
                }

                &:hover {
                  background: rgba(0,0,0,0.05);
                }
              }
            }
          }
          .pagination {
            li {
              a {
                font-size: 1.2rem;
              }
            }
          }
        }
      }
    }
  }
}

.modal-wrapper {
  .modal-content-container {
    max-height: 96vh;
    overflow: hidden;
    .custom-card {
      &.modal-card {
        overflow: hidden;

        .custom-card-header {
          .custom-card-title {
            button {
              background: transparent;
            }
            .btn-action {
              margin-right: 2rem;
              box-shadow: none;
              color: $black;
            }
          }
        }

        .custom-card-body {
          overflow-y: scroll;
          padding: 1rem;

          .variants-settings-form {
            .form-label {
              font-size: 1.2rem;
            }

            .input-container {
              span {
                display: none;
              }
            }
          }
        }
      }
    }
  }
}

.inquiry-list-filters {
  display: flex;
  justify-content: space-between;
  padding: 2.4rem;
  background: rgba(0,0,0,0.03);
  margin-bottom: 2rem;
  border-radius: 0.4rem;

  form {
    flex: 1;

    .form-label {
      font-size: 1.2rem;
      font-weight: 500;
      letter-spacing: 0;
      height: 1.5rem;
    }

    .input-container {
      input {
        height: 4rem;
        border-radius: 0.4rem;
        border: 0.1rem solid #d3d3d3;
        padding: 1.2rem;
      }
    }

    .rs-picker {
      height: 4rem;

      .rs-picker-toggle.rs-btn .rs-picker-toggle-caret {
        top: 1.1rem;
        left: 0.6rem;
      }
    }
    .basic-multi-select {
      .select__control {
        width: 100%;
        min-height: 4rem;
        max-height: 4rem;
        border-radius: 0.4rem;
        border: 0.1rem solid #d3d3d3;

        .select__value-container {
          padding: 1rem;

          .select__placeholder {
            font-weight: 400;
          }

          &.select__value-container--has-value {
            padding: 0 0.4rem;
          }
        }

        .select__multi-value {
          background-color: #fff;
          padding: 0;
          font-size: 1.4rem;
          font-family: "Montserrat", sans-serif;
          font-weight: 400;
          letter-spacing: normal;

          .select__multi-value__label {
            font-size: 1.4rem;
          }
          .select__multi-value__remove {
            display: none;
          }

          & + div {
            font-family: 'Montserrat', sans-serif;
            font-weight: 400;
            letter-spacing: normal;
          }
        }
      }

      .select__menu {
        .select__menu-list {
          color: #000;
          .select__option {
            letter-spacing: normal;
            font-family: "Montserrat", sans-serif;
            font-weight: 400;
          }
        }
      }
    }
  }

  .label-mock {
    margin-top: 2rem;
  }
}

.variants-settings-form {
  flex: 1;
  margin-top: 2rem;

  .form-control {
    border: none;

    .form-label {
      font-size: 1.2rem;
      font-weight: 500;
      letter-spacing: 0;
      height: 1.5rem;

      &::after {
        content: "*";
        color: $info-color;
        right: -1rem;
        top: 0;
        margin-left: 0.5rem;
      }
    }

    .input-container {
      input {
        height: 4rem;
        border-radius: 0.4rem;
        border: 0.1rem solid #d3d3d3;
        padding: 1.2rem;
      }
    }

    .error-block {
      position: absolute;
      right: 4rem;
      bottom: 3.6rem;
      align-items: center;
      flex-direction: row;

      .error-element {
        display: none;
      }

      &:before {
        content: "!";
        position: absolute;
        top: 0;
        left: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 50%;
        font-weight: 600;
        border: 0.1rem solid $error-block-color;
        color: $error-block-color;
        height: 1.6rem;
        width: 1.6rem;
        font-size: 1.1rem;
        padding-left: 0.2rem;
      }
    }

    &.invalid {
      input {
        border: 0.1rem solid $error-block-color;
      }
    }
  }
}

.confirmation-action-button {
  position: relative;
  float: left;

  .confirmation-action-pane {
    position: absolute;
    top: -4rem;
    right: 0;
    min-width: 10rem;
    background: $white;
    padding: 1.2rem;
    border-radius: 0.4rem;
    display: flex;
    flex-direction: column;
    gap: 1.2rem;
    box-shadow: 0.34rem 0.37rem 1.35rem 0 rgba(0, 0, 0, 0.1);
    z-index: 1;

    h3 {
      font-size: 1.4rem;
      color: $danger;
    }
  }

  button {
    background: none;
    color: $black;

    &:hover:not(:disabled) {
      color: $primary-color;
    }

    &:disabled {
      opacity: 0.4;
    }
  }
}

.badge {
  &.badge-confirm {
    &.yes {
      background: $success-color;
    }
    &.no {
      background: $error-block-color;
    }
  }

  &.badge-status {
    &.status_paid {
      background: $primary-color;
    }
    &.status_active {
      background: $success-color;
    }
    &.status_draft {
      background: $warning-color;
    }
    &.status_expired_draft {
      background: $error-block-color;
    }
    &.status_expired {
      background: $dark-color;
    }
  }
}

.totals-container {
  display: flex;
  flex-direction: column;
  gap: 2.4rem;

  .totals-title {
    font-size: 2rem;
  }
  .totals {
    display: flex;
    gap: 2.4rem;

    .total {
      flex: 1;
      display: flex;
      flex-direction: column;
      position: relative;
      gap: 0.6rem;
      padding: 1.2rem 2.4rem;
      overflow: hidden;
      background: rgba(0,0,0,0.03);
      border-radius: 0.5rem;

      &:before {
        content: '';
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        width: 0.25rem;
        background: $primary-color;
      }

      .total-label {
        font-size: 1.4rem;
      }
      .total-value {
        font-size: 2.4rem;
      }
    }
  }
}

.inquiry-view-card {
  padding: 1rem;
  section {
    padding: 2.4rem;
    margin-bottom: 2.4rem;
    background: rgba(0,0,0,0.03);
    border-radius: 0.4rem;
    letter-spacing: 0;

    h2 {
      font-size: 1.6rem;
      font-weight: 600;
      margin-bottom: 1.2rem;
    }

    table {
      width: 100%;
      tbody {
        tr {
          &:nth-child(odd) {
            background: $white;
          }
          td {
            padding: 0.6rem;

            &:nth-child(2) {
              font-weight: 500;
            }
          }
        }
      }
    }
  }

  .inquiry-email-edit {
    display: flex;
    flex-direction: column;

    form {
      margin-bottom: 3rem;
    }
  }
}

.inquiry-settings-modal {
  .subtitle {
    font-size: 1.8rem;

  }

  .inquiry-documents {
    .inquiry-documents-list {
      .inquiry-documents-item {
        display: flex;
        align-items: center;
        justify-content: space-between;
        font-size: 1.2rem;
        font-weight: 500;
        letter-spacing: 0;
        padding: 1rem;

        &:nth-child(odd) {
          background: rgba(0,0,0,0.05);
        }

        button {
          margin-left: 1rem;
          background-color: transparent;
          color: $danger;
          font-weight: 500;
        }
      }
    }

    .no-data {
      font-weight: 400;
      letter-spacing: 0;
      padding: 1rem 0;
    }
  }

  form {
    .task-form {
      .form-input {

        .rs-uploader-file-items {
          border-width: 0.1rem;

          .rs-uploader-file-item-content {
            font-family: "Montserrat", sans-serif;
            .rs-uploader-file-item-title {
              font-size: 1.4rem;
              font-weight: 500;
              letter-spacing: 0;
            }

            .rs-uploader-file-item-size {
              font-size: 1.2rem;
              font-weight: 500;
              letter-spacing: 0;
              color: #808588;
            }
          }
        }
      }
    }
  }
}

.add-inquiry-modal {
  .modal-content-container {
    width: 90%;
    height: 90%;

    .custom-card {
      height: 100%;
      max-width: 100%;

      .inquiry-wrapper {
        height: 100%;

        iframe {
          height: 100%;
          width: 100%;
        }
      }
    }
  }
}
