.form-control {
  // toDo fix structure, form-control-container > .form-control ... ?
  position: relative;
  display: flex;
  width: initial;
  border-radius: $border-radius;
  border: 0.1rem solid $input-border-color;
  background: $white-color;
  color: $theme-second-color;
  font-size: $base-font-size;

  .input,
  .select {
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    border: 0;
    padding: 0 1rem;
    background: transparent;
    font-family: Montserrat, sans-serif;
    font-size: $small-base-font-size;
    font-weight: 500;
    color: rgba(0, 0, 0, 0.6);

    &:focus {
      outline: none;
    }
  }

  .phone-control {
    display: flex;
    align-items: center;

    .basic-single {
      margin-right: 1rem;
      width: 12rem;

      .country-select-value {
        height: 100%;
        font-family: $montserrat-font-family;
        font-size: $base-ms-font-size;
        font-weight: 500;
        letter-spacing: 0.09rem;
        color: rgba($black-color, 0.6);
      }
    }

    .input {
      width: 100%;
    }
  }
}

.input-container {
  width: 100%;
}

// TODO PPP, do porządku, jak wszystko zresztą
.input-search {
  position: relative;
  height: 5rem;
  padding-left: 4rem;
  border: 0.2rem solid $input-border-color;
  border-radius: $border-radius;

  &:before {
    content: "";
    position: absolute;
    top: calc(50% - 1rem);
    left: 1.5rem;
    width: 2rem;
    height: 2rem;
    background: url("data:image/svg+xml,%3C%3Fxml version='1.0' encoding='utf-8'%3F%3E%3Csvg version='1.1' id='Layer_1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' viewBox='0 0 512 512' style='enable-background:new 0 0 512 512;' xml:space='preserve' fill='%23888'%3E%3Cg%3E%3Cpath d='M367.6,331.1l138.4,138.6c9.2,10.8,7.8,27-3,36.2c-9.7,8.2-23.9,8.1-33.4-0.2L331.4,367.6 c-89.6,69.6-218.6,53.4-288.2-36.2c-69.6-89.6-53.4-218.6,36.2-288.2S298-10.2,367.6,79.4c57.6,74.1,57.6,177.9,0,252V331.1z M205.3,359.4c85.1,0,154-69,154-154c0-85.1-69-154-154-154c-85.1,0-154,69-154,154C51.3,290.4,120.3,359.4,205.3,359.4z'/%3E%3C/g%3E%3C/svg%3E%0A") no-repeat center
  }

  .input-container {
    flex: 1;
    display: flex;

    input {
      border: none;
    }
  }
}

//.input-group {
//  .input {
//    display: flex;
//    width: 100%;
//    height: 5rem;
//    padding: 0.35rem 1.5rem;
//    font-size: $base-font-size;
//    font-weight: 400;
//    line-height: 1.5;
//    color: $black-color;
//    background-color: $white-color;
//    border: 0.2rem solid $input-border-color;
//    border-radius: 0.4rem;
//    margin-bottom: 1rem;
//    &:disabled {
//      background-color: $input-disabled-background;
//      opacity: 0.5;
//      pointer-events: none;
//    }
//    &::placeholder {
//      color: $input-text-color-gray;
//      font-weight: 500;
//      line-height: 3.32;
//      letter-spacing: 0.47px;
//    }
//  }
//}

.custom-number-input-wrapper {
  display: inline-flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
  margin-right: 2rem;

  .custom-number-input {
    border: 0.2rem solid $input-border-color;
    border-radius: $border-radius;
    text-align: center;
    min-width: 12rem;
    max-width: 12rem;
    height: 4rem;
    appearance: textfield;
    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
      -webkit-appearance: none;
    }
    &:focus, &:hover {
        appearance: textfield;
    }
  }
  .value-handler-button {
    outline: none;
    -webkit-appearance: none;
    background-color: transparent;
    border: none;
    align-items: center;
    justify-content: center;
    top: 0;
    bottom: 0;
    width: 3.5rem;
    cursor: pointer;
    margin: 0.7rem 0;
    position: absolute;
    z-index: 3;
    color: $input-text-color;
    &::after {
      font-size: $m-font-size;
      display: inline-block;
      width: 1rem;
      height: 1rem;
    }

    &.decrement {
      left: 0;
      border-right: 0.1rem solid $input-border-color;

      &::after {
        content: "-";
      }
    }
    &.increment {
      right: 0;
      border-left: 0.1rem solid $input-border-color;

      &::after {
        content: "+";
      }
    }
  }
}
