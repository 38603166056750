body {
  margin: 0;
  font-family: $font-family;
  font-size: $base-font-size;
  color: $base-text-color;
  font-weight: 400;
  background: $white-color;
  position: relative;
  text-align: left;

  .view-header {
    display: flex;
    align-items:center;
    justify-content: space-between;
    margin-bottom: 2rem;
    min-height: 3rem;

    .view-title {
      display: flex;
      font-size: 2.2rem;
      color: $view-title-color;
      font-weight: 500;
    }
  }

  .additional-info {
    display: flex;
    align-items: center;
    margin-bottom: 1rem;

    span {
      margin-right: 0.5rem;
    }
  }

  .view-subtitle {
    font-size: $m-font-size;
    font-weight: bold;
    line-height: 1.33;
    color: $view-title-color;
    margin-bottom: 2.2rem;
  }

  .filter-container {
    display: flex;
    align-items: center;
    margin-bottom: 2rem;
  }
  .sr-only {
	border: 0 !important;
	clip: rect(1px, 1px, 1px, 1px) !important;
	-webkit-clip-path: inset(50%) !important;
	clip-path: inset(50%) !important;
	height: 1px !important;
	margin: -1px !important;
	overflow: hidden !important;
	padding: 0 !important;
	position: absolute !important;
	width: 1px !important;
	white-space: nowrap !important;
}
}
