.color-theme {
  color: $theme-color;
}

.color-success {
  color: $success-color;
}

.color-primary {
  color: $primary-color;
}

.color-danger {
  color: $danger-color;
}

.color-warning {
  color: $warning-color;
}

.color-info {
  color: $info-color;
}

// Default button for long list being expanded/hidden

.default-expand-list-button {
    display: inline-flex;
    justify-content: center;
    appearance: none;
    color: $info-color;
    text-decoration: underline;
    background-color: transparent;
    letter-spacing: 0.06rem;
    font-weight: 500;
    margin-left: 0.25rem;
    margin-top: 2.5rem;
    &::after {
      content: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAkAAAAFCAMAAACgjTZZAAAAAXNSR0IB2cksfwAAAAlwSFlzAAALEwAACxMBAJqcGAAAAEtQTFRFmtn0bsjvAAAAtuT3Vb/suOT3B6LkUr7spt31BqHkX8Ptu+X4C6PkRbnrl9j0AqDjaMbux+r5EaXlIqznAJ/jec3w0e76L7HojtTzgUGn5wAAABl0Uk5T//8A/////////////////////////////1f/nzYAAAAySURBVHicY2BgZAIBZhYGVjZ2IIODk4uBiZuHl4mPX4CJgYlJUEhYRJQJxGISE5cAkgAaGwFVc8U4wQAAAABJRU5ErkJggg==");
      margin-left: 1rem;
      display: flex;
    }
    &.long-list {
      &::after {
        transform: rotate(180deg);
        top: 0.3rem;
      }
    }
  }
