.badge {

  .badge-text {
    padding: 0.5rem 1.5rem;
    color: $white-color;
    font-size: 1.2rem;
    border-radius: 0;
    font-weight: 700;
    text-transform: uppercase;

    &.draft, &.status_draft {
      background-color: $info-color;
    }

    &.expired, &.status_expired, &.draft_expired {
      background-color: $warning-color;
    }

    &.active, &.status_active {
      background-color: $success-color;
    }

    &.paid, &.status_paid {
      background-color: $purple-color;
    }

    &.payment_status_not_paid {
      background-color: $warning-color;
    }

    &.payment_status_paid, &.status_modified {
      background-color: $info-color;
    }

    &.status_no_payment {
      background-color: $warning-color;
    }

    &.status_expired_draft {
      background-color: $danger-color;
    }

    .badge-tooltip {
      cursor: pointer;
      text-decoration: none;
      font-weight: 600;
      letter-spacing: 0.1rem;
    }
  }
}
