.navbar-nav {
  padding: 0 1rem;
  list-style: none;
  margin-bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  position: relative;

  .navbar-item {
    display: flex;
    margin-right: 4rem;
    min-width: 12rem;

    &:hover {
      i {
        color: $black-color;
      }

      a {
        color: $primary-color;
      }
    }

    &.item-basket:hover {
      a {
        font-weight: 800;
      }
    }

    &.usermenu-dropdown {
      position: relative;
      margin: 0;
      i {
        margin: 0;
      }
    }

    i {
      margin-right: 1rem;
      color: $datepicker-header-color;
      font-size: $m-font-size;
    }

    a {
      font-family: Montserrat, sans-serif;
      font-size: $base-font-size;
      font-weight: 500;
      color: $usermenu-color;
      text-decoration: none;

      .balance-amount {
        font-weight: 800;
        margin: 0 0.5rem;
      }
    }
  }

  .badge {
    position: absolute;
    top: 0;
    right: 0;
    font-size: 1.2rem;
  }

  .dropdown-menu-head {
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;

    .user-icon {
      position: relative;
      cursor: pointer;
      min-width: 3.6rem;
      min-height: 3.6rem;
      background-color: $input-border-color;
      background-repeat: no-repeat;
      background-position: center;
      background-size: cover;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 50%;
      font-size: 2.4rem;
      text-decoration: none;

      .user-avatar {
        width: 100%;
        height: 100%;
        border-radius: 50%;
      }
      svg {
        position: absolute;

        circle {
          transition: stroke-dashoffset 0.35s;
          transform: rotate(-90deg);
          transform-origin: 50% 50%;
        }
      }

      .notification {
        position: absolute;
        width: 1.7rem;
        height: 1.7rem;
        box-shadow: -6px 8px 27px 0 rgba(0, 0, 0, 0.1);
        background-color: $secondary-color;
        color: $white-color;
        border-radius: 50%;
        z-index: 99;
        font-family: Montserrat, sans-serif;
        font-size: $xxs-font-size;
        font-weight: 500;
        text-align: center;
        display: flex;
        justify-content: center;
        align-items: center;
        top: 0;
        right: -5px;
      }
    }

    .username {
      margin: 0 1.4rem 0 2rem;
      font-family: Montserrat, sans-serif;
      font-size: $m-font-size;
      font-weight: 500;
      line-height: 2.13;
      letter-spacing: 0.5px;
      color: $username-color;
    }

    .dropdown-icon {
      display: flex;
      font-size: $base-font-size;
    }
  }

  .dropdown-menu {
    position: absolute;
    top: -1rem;
    right: -2rem;
    left: -2rem;
    display: flex;
    flex-direction: column;
    line-height: 1.4;
    overflow: hidden;
    padding: 1rem 2rem;
    border-radius: $border-radius;
    box-shadow: 0 0 7.5px 0 rgba(0, 0, 0, 0.1);
    background-color: $white-color;
    border: none;
    color: $username-color;

    .dropdown-menu-head {
      margin-bottom: 2.3rem;
    }

    .dropdown-menu-body {
      padding: 0;
      margin-bottom: 0;
      list-style: none;

      li {
        margin: 0;
        border-radius: 0.2rem;

        a {
          padding: 1.1rem 2.34rem;
          font-family: Montserrat, sans-serif;
          font-size: $base-font-size;
          font-weight: 500;
          letter-spacing: 0.1rem;
          color: $usermenu-color;

          i {
            margin-right: 1rem;
            color: $datepicker-header-color;
          }
        }

        &.active,
        &:active,
        &:focus,
        &:hover {
          background: transparentize($theme-second-color,0.97);

          > a {
            color: $theme-second-color;
            background: transparent;
          }
        }
      }
    }
  }
}
