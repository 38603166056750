.week-calendar-container {
  display: flex;
  justify-content: center;
  min-height: 20rem;

  .controls {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 2.3rem;
    height: 2.3rem;
    background-color: $time-slot-controls;
    color: $info-color;
    border-radius: 50%;

    &.prev-control:disabled {
      background-color: rgba(41, 35, 92, 0.1);
      cursor: not-allowed;
    }
  }

  .week-calendar-controls {
    display: flex;
    flex-direction: column;

    .dates-wrapper {
      display: flex;
      flex: 1;

      .date-details {
        display: flex;
        flex-direction: column;
        margin: 0 0.5rem;

        .date {
          display: flex;
          flex-direction: column;
          // flex: 1;
          margin-bottom: 1rem;

          p {
            text-shadow: -0.39rem 0.52rem 1.35rem rgba(0, 0, 0, 0.15);
            font-family: Montserrat, 'sans-serif';
            font-size: $small-font-size;
            font-weight: 500;
            letter-spacing: 0.03rem;
            text-align: center;
            color: $username-color;

            &.week-day {
              font-weight: bold;
              color: $info-color;
            }
          }
        }
      }
    }

    .expand-calendar {
      font-family: Montserrat, sans-serif;
      font-size: $base-font-size;
      font-weight: 500;
      color: $info-color;
      background-color: $white-color;
      display: flex;
      justify-content: center;
      align-items: center;
      align-self: center;
      margin: 1.2rem 0;
    }
  }
}

.time-slots-container {
  .slots {
    .radio-control {
      .radio-wrapper {
        input {
           display: none;

          &:checked + .radio-label {
            background-color: $secondary-color;
            color: $white-color;
          }

          &:disabled + .radio-label {
            background-color: $white-color;
            text-decoration: line-through;
            text-decoration-color: $time-slot-background;
            box-shadow: none;
            letter-spacing: 0;

            &:hover {
              cursor: not-allowed;
              color: $black-color;
              font-weight: 400;
            }
          }
        }

        .radio-label {
          display: flex;
          flex: 1;
          margin: 0.2rem 0;
          padding: 0.5rem;
          border-radius: $border-radius;
          background-color: $time-slot-background;
          color: $black-color;
          font-size: 1.2rem;
          font-weight: 400;
          letter-spacing: 0.02rem;

          &:hover {
            background-color: $secondary-color;
            color: $white-color;
            font-weight: 600;
            cursor: pointer;
            letter-spacing: 0;
          }
        }
      }
    }
  }
}
