.checkbox-container {
  display: inline-flex;
  justify-content: flex-start;
  align-items: center;

  .checkbox-wrapper {
    display: inline-flex;
    align-items: center;
    position: relative;
    font-size: $small-base-font-size;
    padding-left: 3rem;
    line-height: 1.3;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    color: $black-color;
    font-weight: 500;

    .checkbox-input {
      position: absolute;
      opacity: 0;
      cursor: pointer;
      z-index: 20;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      height: 2.1rem;
      width: 2.1rem;
      &:disabled {
        cursor: not-allowed;
      }
    }

    .checkbox-input:disabled ~ .checkbox-checkmark {
      opacity: 0.6;
      cursor: not-allowed;
      &:after {
        opacity: 0.6;
      }
    }

    .checkbox-input:checked ~ .checkbox-checkmark:after {
      content: '';
    }

    .checkbox-checkmark {
      display: flex;
      justify-content: center;
      align-items: center;
      -webkit-appearance: none;
      position: absolute;
      height: 2.1rem;
      width: 2.1rem;
      margin: 0;
      top: 0.2rem;
      left: 0;
      border: 0.2rem solid $border-color;
      background-color: $white-color;
      transition: all 0.3s ease-in-out;
      outline: none;

      &:after {
        position: absolute;
        height: 1.9rem;
        width: 2.3rem;
        background-image: url("data:image/svg+xml;charset=UTF-8,%3csvg xmlns='http://www.w3.org/2000/svg' width='15' height='13'%3e%3cpath fill='%23009FE3' fill-rule='evenodd' d='M14.688 2.084L5.721 12.583c-.409.477-1.074.477-1.485 0l-3.96-4.61c-.41-.478-.41-1.251 0-1.729.41-.478 1.075-.478 1.485 0l3.215 3.742L13.202.355c.41-.478 1.076-.478 1.486 0 .409.478.409 1.251 0 1.729z'/%3e%3c/svg%3e");
        background-repeat: no-repeat;
        background-size: cover;
        left: 0.2rem;
        bottom: 0.2rem;
      }

      &:checked {
        color: $secondary-color;
      }
    }

    .checkbox-label {
        font-size: $base-font-size;
        font-weight: 500;
        color: rgba($black-color, 0.6);
        line-height: 1.54;
        letter-spacing: 0.39px;
    }

    &.additional-clause-checkbox {
      margin: 0.75rem 0;

      .checkbox-label {
        margin-top: 0.2rem;
        font-size: $small-base-font-size;
        letter-spacing: 0.08rem;
      }

      .checkbox-checkmark {
        height: 1.6rem;
        width: 1.6rem;
      }
    }
  }
}
