@import "./form-controls/checkbox.scss";
@import "./form-controls/input";
@import "./form-controls/switch";
@import "./form-controls/select";
@import "./form-controls/multiselect";
@import "./form-controls/control-group";
@import "./form-controls/form-grid";
@import "./form-controls/radio-input";
@import "./form-controls/checkbox";
@import "./form-controls/file-upload";

.input-group {
  margin-bottom: 0.75rem !important;

  .form-label {
    margin-bottom: 0.5rem;

    ~ input {
      height: 4rem !important;
    }
  }
}

.school-search-form {
  display: flex;
  flex-direction: row;
  padding: 4rem;
  background-color: $info-color;
  border-radius: 0.6rem;

  .school-search-row {
    display: flex;
    flex: 1;
    justify-content: space-between;

    .study-form-control {
      padding: 0 1rem;

      label[for="whereToStudy"] + .basic-single,
      label[for="studySubject"] + .basic-single {
        .select__control {
          .select__indicators {
            display: none;
          }
        }
      }
    }
  }

  label {
    color: $white-color;
  }
}

.sort-form {
  .sorting-control {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;

    .form-label {
      margin-right: 1rem;
      color: $black-color;
    }

    .basic-single {
      .select__control {
        min-width: 20rem;
      }
    }
  }
}

.auth-form,
.add-schedule-event-form,
.default-form,
.task-form {
  display: flex;
  flex-direction: column;
  padding: 4rem 4rem 3rem;
  background-color: $auth-form-background;

  &.payment,
  &.timezone-form,
  &.task-form {
    padding: 0;
    background-color: transparent;
  }

  &.task-form {
    .checkbox-container {
      display: flex;
      flex-direction: row-reverse;
      justify-content: flex-end;
      align-items: center;

      .form-label {
        margin: 1.2rem 0 0 0.5rem;
        font-weight: 500;
        line-height: 1.54;
        letter-spacing: 0.039rem;
        color: rgba($black-color, 0.6);
      }
    }

    .textarea-container {
      display: flex;
      width: 100%;
      flex: 1;

      textarea {
        padding: 0.5rem 1.5rem;
        font-weight: 500;
        resize: vertical;
        border-radius: $button-border-radius;
        border: 0.2rem solid $input-border-color;
      }
    }

    .form-input {
      display: flex;
      flex-direction: column;
      flex: 1;

      .rs-uploader-trigger {
        input {
          display: none;
        }

        .rs-uploader-trigger-btn {
          font-size: $base-font-size;
          font-weight: 500;
          letter-spacing: 0.028rem;
          color: $info-color;
          text-decoration: underline;
          cursor: pointer;
        }
      }

      .rs-uploader-file-items {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        flex: 1 1;
        height: 5rem;
        padding: 0.5rem 1.5rem;
        border-radius: 0.4rem;
        border: 0.2rem solid $input-border-color;
        font-weight: 500;
        background: $white-color;

        .rs-uploader-file-item {
          display: flex;
          font-family: Montserrat, sans-serif;
          margin-bottom: 0.5rem;

          .rs-uploader-file-item-icon-wrapper {
            margin-right: 0.5rem;
          }

          .rs-uploader-file-item-btn-remove {
            margin-left: 0.5rem;

            &:hover {
              color: $info-color;
            }
          }
        }
      }

      &.empty {
        .rs-uploader-file-items {
          display: none;
        }
      }

      &.uploaded {
      }
    }
  }

  &.profile {
    background-color: $white-color;
    padding: 0;
    margin-top: 2rem;

    .button-container {
      padding: 0;
      justify-content: flex-end;
    }
  }

  .form-control {
    position: relative;
    margin-bottom: 1.5rem;
    border: none;
    background: transparent;

    &.col-xl-4 {
      width: 33.33333%;
    }

    &.col-xl-6 {
      width: 50%;
    }

    &.col-xl-12 {
      width: 100%;
    }

    .error-block {
      position: absolute;
      bottom: -1.25rem;
      flex-direction: row;
      font-size: $small-font-size;

      .error-element {
        padding-right: 0.5rem;
      }
    }

    .StripeElement {
      display: flex;
      align-items: center;
      height: 5rem;
      padding: 0.35rem 1.5rem;
      color: $black-color;
      background-color: $white-color;
      border: 0.2rem solid $input-border-color;
      width: 100%;
      font-size: 1.6rem;
      font-weight: 400;
      line-height: 1.5;
      border-radius: $border-radius;
      font-family: Montserrat, sans-serif;

      &.StripeElement--invalid {
        border: 0.1rem solid $error-block-color;
      }

      & > div {
        flex: 1;
      }
    }

  }

  .button-container {
    display: flex;
    justify-content: center;
    padding: 0 1.5rem;
    width: 100%;
    margin-top: 2rem;
  }

  .agreement-container {
    &.checkbox-hide {
      .checkbox-container {
        .checkbox-wrapper {
          padding-left: 0;

          .checkbox-checkmark {
            display: none;
          }
        }
      }
    }

    .checkbox-container {
      .checkbox-wrapper {
        .checkbox-label {
          margin-top: 0.25rem;
          font-size: $small-base-font-size;

          a {
            color: rgba($info-color, 0.6);
            font-size: $small-base-font-size;
          }
        }
      }
    }
  }

  .login-submit-button {
    .form-label {
      display: none;
    }
  }

  .reset-password-form {
    display: flex;
    flex: 1;
    justify-content: center;
    align-items: flex-start;
    flex-wrap: wrap;

    .reset-button {
      margin: 2.7rem 0 1rem 1rem;
    }
  }

  .register-form {
    margin-top: 2rem;
    position: relative;

    &::before {
      position: absolute;
      top: 0;
      left: 1rem;
      content: "*";
      color: $info-color;
      font-size: $m-font-size;
      font-weight: 600;
    }

    &::after {
      position: absolute;
      top: 0;
      left: 2rem;
      content: "Pola obowiązkowe";
      color: $black-color;
      font-weight: 500;
    }
  }

  .required {
    .form-label {
      position: relative;

      &::after {
        content: "*";
        color: $info-color;
        right: -1rem;
        top: 0;
        margin-left: 0.5rem;
      }
    }
  }

  .form-label {
    display: block;
    width: 100%;
    text-align: left;
    font-weight: 700;
    margin-bottom: 0.8rem;
    color: $black-color;
    font-size: $base-ms-font-size;
    min-height: 1.7rem;

    &.labelHidden {
      display: none;
    }
  }

  input[type="text"],
  input[type="number"],
  input[type="email"],
  input[type="tel"],
  input[type="password"] {
    display: block;
    width: 100%;
    height: 5rem;
    padding: 0.35rem 1.5rem;
    font-size: 1.6rem;
    font-weight: 400;
    line-height: 1.5;
    color: $black-color;
    background-color: #fff;
    border: 0.2rem solid $input-border-color;
    border-radius: $border-radius;

    &:disabled {
      background-color: #ebebeb;
      opacity: 0.5;
      pointer-events: none;
    }

    &::placeholder {
      color: rgba($black-color, 0.6);
      font-weight: 500;
      line-height: 3.32;
      letter-spacing: 0.47px;
    }
  }

  .amount-input {
    position: relative;

    .input-container {
      input + span {
        display: none;
      }
    }
  }

  &.service-change-form {
    background-color: transparent;
    padding: 1rem;

    .checkbox-container {
      margin-top: 0.5rem;

      .checkbox-label {
        a {
          color: $info-color;
        }
      }

      .checkbox-checkmark {
        top: 0;
      }
    }

    .btn-row {
      .btn-container {
        justify-content: center;
        align-items: center;
        margin-bottom: 0;
      }
    }
  }
}

.add-schedule-event-form {
    padding: 0;
    background: transparent;

    .add-schedule-event-control {
        margin: 0;
        padding-top: 0.5rem;
        padding-bottom: 0.5rem;
        border-bottom: 0.1rem solid $table-bottom-border-color;
        border-right: 0.1rem solid $table-bottom-border-color;

        &:last-child {
            border-right: none;
        }

        &.action {
            display: flex;
            align-items: center;
            justify-content: center;

            .btn {
                &:hover {
                    background: transparent;
                }

                i {
                    margin: 0;
                    font-size: $ml-font-size;
                }
            }
        }

        &.event-name {
            background-color: $file-uploader-background;
        }
    }

    .form-label {
        display: none;
    }
}

.assign-materials {
  display: flex;
  flex-direction: column;
  max-height: 20rem;
  overflow: scroll;
  overflow-x: hidden;

  .row {
    .applicant-checkbox {
      display: flex;
      flex-direction: row-reverse;
      justify-content: flex-end;
      margin-bottom: 0;
      padding: 1.3rem 1rem;


      .form-label {
        margin: 0.4rem 0 0 1rem;
      }
    }

    &:nth-child(odd) {
      background-color: rgba($secondary-color, 0.05);

      .applicant-checkbox {
        padding: 0.6rem 1rem;
      }
    }
  }
}

a {
  color: $primary-color;
  text-decoration: underline;
}

@media only screen and (max-width: $breakpoint-medium) {
  .auth-form {
    .form-control {
      &.col-xl-4,
      &.col-xl-6 {
        width: 100%;
      }
    }
  }
}
