.modal-wrapper {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1040;
  justify-content: center;
  align-items: center;
  display: none;
  padding: 0 2rem;

  .modal-background {
    position: fixed;
    z-index: 1041;
    width: 100vw;
    height: 100vh;
    background-color: rgba(0, 9, 14, 0.7);

    &.bg-local {
      position: absolute;
      top: 0;
      left: 0;
      height: calc(100vh - 70px);
    }
  }

  .modal-content-container {
    box-shadow: 0 0.2rem 0.5rem rgba(0, 0, 0, 0.1);
    display: flex;
    z-index: 1042;
    flex-direction: column;
    background-color: #fff;
    background-clip: border-box;
    border-radius: $border-radius;
    min-width: 50rem;
    height: auto;

    .modal-header {
      display: flex;
      align-items: center;
      justify-content: space-between;
      font-size: 2rem;
      font-weight: 400;

      & + .modal-body {
        padding-top: 0;
      }
    }

    .modal-body {
      padding: 2rem 2.5rem;
      flex: 1 1 auto;
      min-height: 0.1rem;
      position: relative;
    }

    .modal-footer {
      padding: 2rem 2.5rem;
      display: flex;
      align-items: center;
      justify-content: center;
      border-top: 0.1rem solid #e3eaef;
    }
  }

  &.visible {
    display: flex;
  }

  &.local {
    position: absolute;
    //z-index: 1027;
  }

  &.custom {
    position: absolute;
    min-width: 50rem;
    min-height: 50rem;
    align-items: flex-start;
    left: 10rem;

    .modal-background {
      top: 0;
      left: 0;
      background-color: transparent;
    }

    .modal-content-container {
      box-shadow: 0 0 7.5px 0 rgba(0, 0, 0, 0.1);
    }
  }
}
