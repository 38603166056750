.modal-header {
  position: relative;
  align-items: center;
  padding: 0 0 2rem;
  color: $view-title-color;
  font-size: $m-font-size;
  font-weight: bold;
  border-bottom: none;

  .modal-title {
    text-shadow: 0.44rem 0.48rem 1.35rem rgba($black-color, 0.15);
    font-family: Montserrat, sans-serif;
    font-size: $x-font-size;
    font-weight: 500;
    line-height: 1.4;
    letter-spacing: 0.031rem;
    color: $black-color;
  }

  .btn-modal-close {
    position: absolute;
    top: -3rem;
    right: -1rem;
    width: 4rem;
    height: 4rem;
    font-size: 1.6rem;
    font-weight: 700;
    color: $close-modal-color;
    background: transparent;

    &:hover,
    &:active {
      opacity: 0.5;
    }
  }
}

.modal-body {
  padding: 4rem 0 2rem;
  overflow: hidden auto;

  p {
    font-family: Montserrat, sans-serif;
    font-size: $base-m-font-size;
    font-weight: 500;
    line-height: 1.27;
    letter-spacing: 0.045rem;
    color: rgba($black-color, 0.6);
  }

  .button-container {
    padding: 0;
  }

  &.aftercare-event-modal {
    padding: 0 0.2rem;
    max-height: 85vh;
  }

  &.consultation-cancellation-modal {
    width: 50rem;

    p {
      margin-bottom: 0.5rem;

      &:last-of-type {
        margin-bottom: 1rem;
      }
    }

    ol {
      padding-left: 3rem;

      li {
        margin-bottom: 0.5rem;
      }
    }
  }
}

.modal-footer {
  padding: 2rem 0 0;
  .btn {
    margin: 0;
    min-width: 8rem;
  }
}
