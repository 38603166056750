.badge {
  font-size: $xxs-font-size;
  color: $white-color;
  border-radius: $border-radius;
  padding: 0.5rem 1rem;
  float: left;
  font-weight: 600;

    &.noAccountRegistrationConfirmation,
    &.inProgress {
      background: $warning-color;
    }

    &.noRegistrationSurvey {
      background: $secondary-color;
    }

    &.registrationSurveyApproval {
      background: lighten($secondary-color, 10%);
    }

    &.noOnboardingSurvey {
      background: $purple-color;
    }

    &.mentorDeactivated {
      background: $danger-color;
    }

    &.onboardingSurveyApproval {
      background: lighten($purple-color, 10%);
    }

    &.mentorActive,
    &.completed {
      background: $success-color;
    }
}

