.auth-page {
  .auth-content {
    min-height: 100vh;
    display: flex;
    flex-direction: column;

    .auth-section {
      display: flex;
      justify-content: center;
      padding: 0 2rem 2rem;

      .auth-form-header {
        margin-bottom: 2rem;

        .auth-title {
          font-size: $xxxxl-font-size;
          font-weight: 500;
          color: $black-color;
          letter-spacing: 0.1rem;
          text-align: center;
        }
        .auth-subtitle-container {
          margin-top: 3rem;

          .auth-subtitle {
            color: $black-color;
            font-size: $base-font-size;
            line-height: 2;
            text-align: center;
            letter-spacing: 0.1rem;
            .auth-redirect-link {
              padding: 0 0.5rem;
              color: $info-color;
              font-size: $base-font-size;
              font-weight: 500;
              letter-spacing: 0.1rem;
            }
          }
        }
      }
      .registration-confirmation-container {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        padding: 4rem;
        background:$auth-form-background;

        .registration-confirmation-info {
          margin-bottom: 2rem;
          color: $gray-text-color;
          font-weight: 600;
          text-align: center;
          letter-spacing: 0.1rem;
        }
      }

      // Social login button, not used for now

      //.auth-social-buttons {
      //  display: flex;
      //  flex-direction: column;
      //  justify-content: center;
      //  align-items: center;
      //  width: 100%;
      //  padding-bottom: 4rem;
      //  background-color: $auth-form-background;
      //  font-size: $base-m-font-size;
      //  color: $black-color;
      //  letter-spacing: 0.1rem;
      //
      //  .button-wrapper {
      //    display: flex;
      //    justify-content: center;
      //    align-items: center;
      //    margin-top: 2rem;
      //    flex-wrap: wrap;
      //    .facebook-button {
      //      background-color: $auth-button-facebook-color;
      //      color: $white-color;
      //      margin: 0 1rem;
      //      padding-left: 1.5rem;
      //      .facebook {
      //        background: url('../images/facebook_button_icon.png') no-repeat;
      //      }
      //    }
      //    .google-button {
      //      background-color: $white-color;
      //      color: $auth-button-gray-color;
      //      margin: 0 1rem;
      //      padding-left: 1.5rem;
      //      .google {
      //        background: url('../images/google_button_icon.png') no-repeat;
      //      }
      //      &::after {
      //        content: url('../images/arrow_right_small.png');
      //      }
      //    }
      //    .icon {
      //      width: 3rem;
      //      height: 3rem;
      //      display: flex;
      //      margin-right: 1rem;
      //    }
      //  }
      //}
    }
  }
}
