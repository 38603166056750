// toDO PPP , move form-control styles here
// quick fix for current form style mess

.basic-multi-select {
  width: 100%;

  .select__control {
    border-radius: $border-radius;
  }
}

.school-search-form {
  .basic-multi-select {
    .select__control {
       border: none;
       box-shadow: -0.6rem 0.8rem 2.7rem 0 $datepicker-filter;
    }
  }
}

.fields-filters {
  .basic-multi-select {
    .select__control {
      min-height: 5rem;

      .select__indicators {
        .select__clear-indicator {
          display: none;
        }
      }

      .select__value-container {
        height: 2.6rem;
        padding: 0 0.8rem;

        .select__multi-value {
          background-color: $white-color;
          margin-right: 0.4rem;
          max-width: 80%;

          .select__multi-value__label {
            font-size: $base-font-size;
            padding: 0;
          }

          .select__multi-value__remove {
            display: none;
          }
        }
      }
    }
  }

  .basic-single {
    .select__group {
      padding-top: 0;
      padding-bottom: 0;

      &:nth-child(even) {
        background-color: #f7f7f7;
      }

      //&:hover {
      //  //background-color: #009fe3;
      //  color: #fff;
      //
      //  .grouped-label-container {
      //    cursor: pointer;
      //
      //    .grouped-label {
      //      color: #fff;
      //      opacity: 1;
      //    }
      //  }
      //}
    }
  }

  label[for=school] + .basic-single {
    .select__control {
      .select__indicators {
        display: none;
      }
    }
  }
}

.elastic-search-select {
  .basic-single {
    .select__control {
      min-height: 5rem;

      .select__indicators {
          display: none;
      }
    }
  }
}

//.horizontal {
  .basic-multi-select {
    .select__control {
      width: 100%;
      min-height: 5rem;

      .select__value-container {
        padding: 0.2rem 1.8rem;

        .select__placeholder {
          font-family: Montserrat, sans-serif;
          letter-spacing: 0;
        }
      }

      .select__dropdown-indicator {
        width: 5rem;
        height: 100%;

        &::after {
          width: 100%;
          height: 100%;
          display: flex;
          justify-content: center;
          align-items: center;
          content: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAoAAAAHCAMAAAAGcixRAAAAAXNSR0IB2cksfwAAAAlwSFlzAAALEwAACxMBAJqcGAAAADZQTFRFAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAt3ZocgAAABJ0Uk5TUG8AEH8wwP+fz5DwIKXg+zwfMxtm8wAAADlJREFUeJwlxlkSgCAQA9EQiCOyqPe/rJOyP7oeUOhqE444rR4XNNKpMcH0SomgHXvSpOIWf/J5/Q83mgGA4aVzwgAAAABJRU5ErkJggg==);
          opacity: 0.5;
        }
        svg {
          display: none;
        }
      }
    }
  }

  .basic-single {
    .select__control {
      .select__value-container {
        height: 100%;

        .select__placeholder {
          font-family: Montserrat, sans-serif;
          letter-spacing: 0;
        }
      }

      .select__dropdown-indicator {
        width: 5rem;
        height: 100%;

        &::after {
          width: 100%;
          height: 100%;
          display: flex;
          justify-content: center;
          align-items: center;
          content: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAoAAAAHCAMAAAAGcixRAAAAAXNSR0IB2cksfwAAAAlwSFlzAAALEwAACxMBAJqcGAAAADZQTFRFAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAt3ZocgAAABJ0Uk5TUG8AEH8wwP+fz5DwIKXg+zwfMxtm8wAAADlJREFUeJwlxlkSgCAQA9EQiCOyqPe/rJOyP7oeUOhqE444rR4XNNKpMcH0SomgHXvSpOIWf/J5/Q83mgGA4aVzwgAAAABJRU5ErkJggg==);
          opacity: 0.5;
        }
        svg {
          display: none;
        }
      }
    }

    .select__option {
      .custom-option {
        display: flex;
        align-items: center;

        .logo-container {
          height: 2rem;
          width: 2rem;
          border-radius: 50%;
          background-color: rgba(0, 0, 0, 0.6);
          margin-right: 1rem;
          overflow: hidden;

          img {
            width: 100%;
            height: 100%;
          }
        }

        .label {
          font-family: Montserrat, sans-serif;
          font-size: $base-font-size;
          font-weight: 500;
          letter-spacing: 0.45px;
          color: $black-color;
        }
      }
    }
  }
//}
.checkbox-wrapper {
  display: flex;
  align-items: center;
  margin-bottom: 1rem;

  .checkbox-input {
    margin-right: 1rem;
  }
}

.consultation-hours {
  align-items: stretch;
  .consultation-options-input-group {
    justify-content: space-between;
  }
}

.country-select-value {
  display: flex;
  align-items: center;

  img {
    height: 2.1rem;
    width: 2.8rem;
    margin-right: 1rem;
  }
}

.custom-select-value {
  display: flex;
  align-items: center;

  .logo-container {
    height: 2rem;
    width: 2rem;
    border-radius: 50%;
    background-color: rgba(0, 0, 0, 0.6);
    margin-right: 1rem;
    overflow: hidden;

    img {
      width: 100%;
      height: 100%;
    }
  }

  .label {
    font-family: Montserrat, sans-serif;
    font-size: $base-font-size;
    font-weight: 500;
    letter-spacing: 0.45px;
    color: $black-color;
  }
}

.grouped-label-container {
  display: flex;
  align-items: center;

  .grouped-label {
    opacity: 0.6;
    font-family: 'Montserrat', sans-serif;
    font-size: 1.2rem;
    font-weight: 600;
    color: rgba(0, 0, 0, 0.6);
    text-transform: none;
  }
}

.group-heading-wrapper {
  display: flex;
  height: 3.5rem;

  .select__group-heading {
    display: flex;
    align-items: center;
    padding: 8px 12px;
    margin-bottom: 0;

    .grouped-label-container {
      .grouped-label {
        color: #000;
        opacity: 1;
      }
    }

    &::after {
      content: "\e844";
      font-family: "feather";
      margin-left: 1rem;
      font-size: 1.4rem;
    }

    &.selected {
      color: #009fe3;

      .grouped-label-container {
        .grouped-label {
          color: #009fe3;
        }
      }

      &::before {
        content: "\e843";
        font-family: "feather";
        margin-right: 1rem;
        font-size: 1.4rem;
      }

      &::after {
        display: none;
      }
    }
  }

  & + div {
    display: none;
    background-color: #fff;

    &.collapsed {
      display: block;

      .select__option {
        padding-left: 3.5rem;
        font-family: "Montserrat", sans-serif;
        font-size: 1.2rem;
        font-weight: 600;
        color: #8d8d8d;
        background-color: #fff;

        &:hover {
          background-color: #009fe3;
          color: #fff;
        }

        &.select__option--is-selected {
          color: #fff;
          background-color: #009fe3;
        }
      }
    }
  }


  &:nth-child(even) {
    background-color: #f7f7f7;
  }
}
