.custom-details {
    .custom-details-title {
        list-style-type: none;
        padding: 2rem;
        border-radius: 0.6rem;
        box-shadow: 0 0 1.5rem 0 rgba(0, 0, 0, 0.1);
        position: relative;
        margin: 0.5rem 0;
        font-weight: 500;
        font-size: $base-m-font-size;
        &::-webkit-details-marker {
            display: none;
        }
        &::after {
            content: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAgAAAAMCAMAAABoQAyvAAAAAXNSR0IB2cksfwAAAAlwSFlzAAALEwAACxMBAJqcGAAAAD9QTFRF9vn86OvukZKUmpye6ezvjY+Qe3x+j5GSjpCRi42O6Ort6u3wioyN5+nslJWXiYuM5efq6+7x5ujrk5SWnJ6gdEqZVgAAAD1JREFUeJxjYEAGjEzMYJqFlY0dTHOwcXIBaW4ONh5ekAAflAYy+AXADEF2Nn4hMIsbweKAaAcaICyCMB0ATAoBlz7n7mkAAAAASUVORK5CYII=');
            position: absolute;
            right: 3rem;
            opacity: 0.6;
            transform: rotate(-90deg);
        }
    }
    &[open] {
        .custom-details-title {
            border: 0.2rem solid $info-color;
            margin-bottom: 2rem;
            &::after {
                transform: rotate(90deg);
            }
        }
    }
}