.carousel-root {
  outline: none;
  max-width: 60rem;

  .carousel {
    position: relative;
    padding: 0 3rem;
    width: 100%;

    & * {
      -webkit-box-sizing: border-box;
      -moz-box-sizing: border-box;
      box-sizing: border-box;
    }

    &:hover {
      .slide {
        .legend {
          opacity: 1;
        }
      }
    }

    img {
      width: 100%;
      display: inline-block;
      pointer-events: none;
    }

    .carousel {
      position: relative;
    }

    .control-arrow {
      -webkit-transition: all 0.25s ease-in;
      -moz-transition: all 0.25s ease-in;
      -ms-transition: all 0.25s ease-in;
      -o-transition: all 0.25s ease-in;
      transition: all 0.25s ease-in;
      opacity: 0.4;
      position: absolute;
      z-index: 2;
      background: none;
      border: 0;
      cursor: pointer;
      outline: 0;
      top: 50%;
      margin-top: -1.3rem;
      font-size: 1.8rem;


      &:before {
        margin: 0 0.5rem;
        display: inline-block;
        border-top: 0.8rem solid transparent;
        border-bottom: 0.8rem solid transparent;
        content: '';
      }

      &:focus, &:hover {
        opacity: 1;
        filter: alpha(opacity=100);
      }

      &.control-disabled {
        opacity: 0;
        filter: alpha(opacity=0);
        cursor: inherit;
        display: none;
      }

      &.control-prev {
        left: 0;

        &:before {
          border-right: 0.8rem solid rgba($black-color, 0.5);
        }
      }

      &.control-next {
        right: 0;

        &:before {
          border-left: 0.8rem solid rgba($black-color, 0.5);
        }
      }
    }

    .thumbs-wrapper {
      margin: 2rem;
      overflow: hidden;
    }

    .thumbs {
      -webkit-transition: all 0.15s ease-in;
      -moz-transition: all 0.15s ease-in;
      -ms-transition: all 0.15s ease-in;
      -o-transition: all 0.15s ease-in;
      transition: all 0.15s ease-in;
      -webkit-transform: translate3d(0, 0, 0);
      -moz-transform: translate3d(0, 0, 0);
      -ms-transform: translate3d(0, 0, 0);
      -o-transform: translate3d(0, 0, 0);
      transform: translate3d(0, 0, 0);
      position: relative;
      list-style: none;
      white-space: nowrap;
    }

    .thumb {
      -webkit-transition: border 0.15s ease-in;
      -moz-transition: border 0.15s ease-in;
      -ms-transition: border 0.15s ease-in;
      -o-transition: border 0.15s ease-in;
      transition: border 0.15s ease-in;
      display: inline-block;
      margin-right: 0.6rem;
      white-space: nowrap;
      overflow: hidden;
      border: 0.3rem solid $white-color;
      padding: 0.2rem;

      &:focus {
        border: 0.3rem solid $snippet-bg-color;
        outline: none;
      }

      &.selected, &:hover {
        border: 0.3rem solid #333;
      }

      img {
        vertical-align: top;
      }
    }

    &.carousel-slider {
      position: relative;
      margin: 0;
      overflow: hidden;

      .control-arrow {
        -webkit-transition: all 0.25s ease-in;
        -moz-transition: all 0.25s ease-in;
        -ms-transition: all 0.25s ease-in;
        -o-transition: all 0.25s ease-in;
        transition: all 0.25s ease-in;
        opacity: 0.4;
        position: absolute;
        z-index: 2;
        background: none;
        border: 0;
        cursor: pointer;
        top: 0;
        color: $white-color;
        font-size: 2.6rem;
        bottom: 0;
        margin-top: 0;
        padding: 0.5rem;

        &:before {
          margin: 0 0.5rem;
          display: inline-block;
          border-top: 0.8rem solid transparent;
          border-bottom: 0.8rem solid transparent;
          content: '';
        }

        &:hover {
          background: transparent;
        }

        &:focus, &:hover {
          opacity: 1;
          filter: alpha(opacity=100);
        }
      }
    }

    .slider-wrapper {
      overflow: hidden;
      margin: auto;
      width: 100%;
      -webkit-transition: height 0.15s ease-in;
      -moz-transition: height 0.15s ease-in;
      -ms-transition: height 0.15s ease-in;
      -o-transition: height 0.15s ease-in;
      transition: height 0.15s ease-in;

      &.axis-horizontal {
        .slider {
          -ms-box-orient: horizontal;
          display: -webkit-box;
          display: -moz-box;
          display: -ms-flexbox;
          display: -moz-flex;
          display: -webkit-flex;
          display: flex;

          .slide {
            flex-direction: column;
            flex-flow: column;
          }
        }
      }

      &.axis-vertical {
        -ms-box-orient: horizontal;
        display: -webkit-box;
        display: -moz-box;
        display: -ms-flexbox;
        display: -moz-flex;
        display: -webkit-flex;
        display: flex;

        .slider {
          -webkit-flex-direction: column;
          flex-direction: column;
        }
      }

      .slider {
        margin: 0;
        padding: 0;
        position: relative;
        list-style: none;
        width: 100%;

        &.animated {
          -webkit-transition: all 0.35s ease-in-out;
          -moz-transition: all 0.35s ease-in-out;
          -ms-transition: all 0.35s ease-in-out;
          -o-transition: all 0.35s ease-in-out;
          transition: all 0.35s ease-in-out;
        }

        .slide {
          min-width: 100%;
          margin: 0;
          position: relative;
          text-align: center;
          display: flex;
          justify-content: center;
          align-items: center;

          img {
            width: 100%;
            vertical-align: top;
            border: 0;
          }

          iframe {
            display: inline-block;
            width: calc(100% - 8rem);
            margin: 0 4rem 4rem;
            border: 0;
          }

          .legend {
            position: absolute;
            bottom: 4rem;
            left: 50%;
            margin-left: -45%;
            width: 90%;
            border-radius: 1rem;
            background: $black-color;
            color: $white-color;
            padding: 1rem;
            font-size: 1.2rem;
            text-align: center;
            opacity: 0.25;
            -webkit-transition: opacity 0.35s ease-in-out;
            -moz-transition: opacity 0.35s ease-in-out;
            -ms-transition: opacity 0.35s ease-in-out;
            -o-transition: opacity 0.35s ease-in-out;
            transition: opacity 0.35s ease-in-out;
          }
        }
      }
    }

    .control-dots {
      position: absolute;
      bottom: 0;
      margin: 1rem 0;
      padding: 0;
      text-align: center;
      width: 100%;
      z-index: 1;

      .dot {
        -webkit-transition: opacity 0.25s ease-in;
        -moz-transition: opacity 0.25s ease-in;
        -ms-transition: opacity 0.25s ease-in;
        -o-transition: opacity 0.25s ease-in;
        transition: opacity 0.25s ease-in;
        opacity: 0.3;
        filter: alpha(opacity=30);
        box-shadow: 0.1rem 0.1rem 0.2rem rgba($black-color, 0.9);
        background: $white-color;
        border-radius: 50%;
        width: 0.8rem;
        height: 0.8rem;
        cursor: pointer;
        display: inline-block;
        margin: 0 0.8rem;

        &.selected, &:hover {
          opacity: 1;
          filter: alpha(opacity=100);
        }
      }
    }

    .carousel-status {
      position: absolute;
      top: 0;
      right: 0;
      padding: 0.5rem;
      font-size: 1rem;
      text-shadow: 0.1rem 0.1rem 0.1rem rgba($black-color, 0.9);
      color: $white-color;
    }
  }
}

@media (min-width: 960px) {
  .carousel .control-dots {
    bottom: 0;
  }
}
