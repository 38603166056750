.nav-link {
    &.active, &:hover {
        .nav-link-icon {
            .menu-icon {
                background-color: $black-color;
            }
        }
    }

    .nav-link-icon {
        .menu-icon {
            width: 2rem;
            height: 2rem;
            mask-size: contain;
            mask-repeat: no-repeat;
            display: flex;
            justify-content: center;
            align-items: center;
            background-color: $white-color;
            &.dashboard {
                mask-image: url(../images/dashboard.svg);
            }
            &.user {
                mask-image: url(../images/user.svg);
            }
            &.file-text {
                mask-image: url(../images/file_text.svg);
            }
            &.contents {
                mask-image: url(../images/contents.svg);
            }
            &.video {
                mask-image: url(../images/video.svg);
            }
            &.book {
                mask-image: url(../images/book.svg);
            }
            &.sales {
                mask-image: url(../images/sales.svg);
            }
            &.pricing {
                mask-image: url(../images/price_tag.svg);
            }
            &.insurances {
                mask-image: url(../images/shield.svg);
            }
            &:hover {
                background-color: $black-color;
            }
        }
    }
}
