.timeLine {
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 100%;
  border: none;
  // border: solid 0.1rem $timeline-border-color;
  font-size: $small-font-size;
  user-select: none;
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
}

/* Main Area */

.timeLine-main {
  flex: 1 1 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  overflow-y: hidden;
}
/* Main Area Header*/

.timeLine-main-header-viewPort {
  flex: 0 0 6rem;
  position: relative;
  height: 6rem;
  width: 100%;
  background-color: $timeline-side-task-background;
  overflow: hidden;
}

.timeLine-main-header-container {
  flex: 0 0 6rem;
  position: relative;
  top: 0;
  left: 0;
  height: 100%;
  display: flex;
  flex-direction: column;
  background-color: $timeline-main-header-background!important;
  overflow: hidden;
  user-select: none;
  justify-content: flex-end;

  .current {
    color: $info-color;

    &.current-date {
      font-weight: 600;
    }
  }
}

.timeLine-main-header-day-item {
  position: absolute;
  display: flex;
  flex-direction: column;
  justify-content: center;
  background-color: $timeline-side-task-background;
  font-size: $xxs-font-size;
  text-align: center;
  // border-right: solid 0.1rem;
  // border-top: solid 0.1rem;
  border-bottom: solid 0.1rem;
  top: 2rem;
  height: 4rem;
  color: white;
}

.header-top {
  height: 50%;
}

.header-bottom {
  height: 50%!important;
}

.timeLine-main-header-top-item {
  position: absolute;
  display: flex;
  flex-direction: column;
  justify-content: center;
  border-right: solid 0.1rem $white-color;
  height: 50%;
  z-index: 91;
}

.timeLine-main-header-day-week {
  flex: 0 0 1.2rem;
  padding: 0.4rem;
  z-index: 90;
}

.timeLine-main-header-day-month {
  top: 0;
  position: sticky;
  flex: 0 0 1.5rem;
  padding: 0.5rem;
  z-index: 90;
}

.timeLine-main-header-time {
  display: flex;
  flex-direction: row;
  align-items: stretch;
  height: 2.2rem;
  justify-content: stretch;
}

.timeLine-main-header-time-item {
  border-left: solid 0.1rem silver;
  border-bottom: solid 0.1rem silver;
  border-top: solid 0.1rem silver;
  text-align: center;
  padding-top: 0.5rem;
}
/* Main Area Data*/

.timeLine-main-data-viewPort {
  flex: 1 1 auto;
  position: relative;
  overflow: hidden;
  background-color: $timeline-side-task-viewport-background;
}
.timeLine-main-data-container {
  position: relative;
  top: 0;
  left: 0;
  height: 100%;
  background-color: $white-color;
}

.timeLine-main-data-row {
  position: absolute;
  width: 100%;
  height: 5rem;
}

.timeLine-main-data-task {
  position: absolute;
  background-color: darkorchid;
  border-radius: 1.4rem;
  color: white;
  text-align: center;
}

.timeLine-main-data-task-side {
  position: absolute;
  width: 1rem;
  cursor: col-resize;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.timeLine-main-data-task-side-linker {
  width: 0.8rem;
  height: 0.8rem;
  border-radius: $border-radius;
  cursor: default;
  z-index: 100;
}
.timeLine-main-data-task-side-linker:hover {
  background-color: black;
  border: solid 0.05rem grey;
}
/* .timeLine-main-data-task:hover {
    background-color:chocolate;
    border:solid 0.2rem darkorchid;
    cursor: move;
} */

.timeLine-main-data-task-description {
  padding: 0.2rem;
  display: flex;
  height: 100%;
  width: 100%;

  .timeLine-main-data-task-icon {
    background-color: $timeline-data-task-icon-color;
    height: 100%;
    width: 2.1rem;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;

    i {
      font-size: $base-m-font-size;
    }
  }

  .timeLine-main-data-task-details {
    display: flex;
    flex: 1;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    margin-left: 0.25rem;

    p {
      font-family: Montserrat, sans-serif;
      font-size: $xxxs-font-size;
      font-weight: 500;
      letter-spacing: 0.03rem;
      color: $black-color;

      &.timeLine-main-data-task-title {
        margin-bottom: 0.15rem;
        font-family: Montserrat, sans-serif;
        font-size: $xxs-font-size;
        font-weight: 600;
        letter-spacing: normal;
        color: $black-color;
      }
    }
  }

  .timeLine-main-data-task-tooltip {
    display: none;
    position: absolute;
    content: attr(data-descr);
    top: 0;
    left: 3rem;
    padding: 0.5rem 1rem;
    border: 0.2rem $info-color solid;
    background-color: $white-color;
    border-radius: 0.4rem;
    color: $black-color;
    font-weight: 600;
    font-size: $small-base-font-size;
    white-space: nowrap;
    z-index: 99999;

    p {
      font-family: Montserrat, sans-serif;
      font-size: $xxxs-font-size;
      font-weight: 500;
      letter-spacing: 0.03rem;
      color: $black-color;

      &.timeLine-main-data-task-title {
        margin-bottom: 0.25rem;
        font-family: Montserrat, sans-serif;
        font-size: $xxs-font-size;
        font-weight: bold;
        letter-spacing: normal;
        color: $black-color;
      }
    }

    &::after {
      content: "";
      width: 0.75rem;
      height: 0.75rem;
      background-color: $white-color;
      transform: rotate(45deg);
      position: absolute;
      top: 0.6rem;
      left: -0.6rem;
      border-left: 0.2rem solid $info-color;
      border-bottom: 0.2rem solid $info-color;
      z-index: 2;
    }
  }

  &:hover {
    .timeLine-main-data-task-tooltip {
      display: block;
    }
  }
}


.timeLine-main-data-verticalLine {
  flex: 1 1 auto;
  height: 100%;
  width: 2.4rem;
  background-color: white;
  border-left-width: 0.05rem;
  border-left-color: $timeline-border-color;
  border-left-style: dashed;
}

/* Side Area */

.timeLine-side-main {
  flex: 0 0 auto;
  width: 10.8rem;
  min-width: 10.8rem;
  display: flex;
  flex-direction: row;
}

.timeLine-side {
  flex: 1 0 10rem;
  display: flex;
  flex-direction: column;
  border-right: solid 0.1rem $timeline-border-color;
}

.verticalResizer {
  flex: 0 0 0.8rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  cursor: col-resize;
  border-right: solid 0.1rem $timeline-border-color;
  height: 100%;
}
.squareGrip {
  flex: 0 0 auto;

  border-radius: 50%;
  height: 0.5rem;
  width: 0.5rem;
  margin: 0.3rem 0;
}

.timeLine-side-title {
  flex: 0 0 6rem;
  display: flex;
  justify-content: center;
  align-items: center;
}

.timeLine-side-task-viewPort {
  position: relative;
  flex: 1 1 auto;
  height: 100%;
  background-color: $timeline-side-task-viewport-background;
  overflow-x: hidden;
  overflow-y: auto;
}

.timeLine-side-task-container {
  position: relative;
  overflow-x: hidden;
  overflow-y: hidden;
  height: auto;
}

.timeLine-side-task-row {
  //position: absolute;
  width: 100%;
  background-color: $timeline-side-task-background;
  border-bottom-width: 0.05rem;
  border-bottom-color: $timeline-border-color;
  border-bottom-style: solid;
  height: 3rem;
  color: $black-color;
  overflow: hidden;
  text-overflow: ellipsis;
  outline: none;
  display: flex;
  cursor: pointer;
  padding: 0.5rem 0.9rem;
  font-family: Montserrat, sans-serif;
  font-size: $small-font-size;
  font-weight: 600;
  letter-spacing: 0.016rem;
}

.timeLine {
  width: 100%;

  .timeLine-side-main {
    width: 22rem;

    .timeLine-side {
      .timeLine-side-title {
        background-color: $white-color;
      }
    }
  }

  .timeLine-main {
     width: 46vw;

    .timeline-header {
      .timeLine-main-header-container {
        height: 6.6rem;
        background-color: $timeline-main-header-background;

        .current {
          color: $info-color;
        }
      }
    }
  }
}
