.custom-card {
  box-shadow: 0 0 1.5rem 0 rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: border-box;
  border-radius: 0.8rem;
  position: relative;

  .custom-card-header {
    padding: 3.9rem 3.9rem 2.4rem;

    .custom-card-title {
      border-bottom: none; //0.1rem solid #ced4da;
      margin: 0;
      font-weight: 500;
      text-shadow: -7.8px 10.4px 27px rgba(0, 0, 0, 0.15);
      font-family: Montserrat, sans-serif;
      font-size: $x-font-size;
      line-height: 1.4;
      color: $black-color;

      .card-title-link {
        text-decoration: none;
        font-weight: 500;
        text-shadow: -7.8px 10.4px 27px rgba(0, 0, 0, 0.15);
        font-family: Montserrat, sans-serif;
        font-size: $x-font-size;
        line-height: 1.4;
        color: $black-color;

        &:hover {
          color: $theme-color;
          cursor: pointer;
        }
      }
    }

    & + .custom-card-body {
      padding-top: 0;
    }
  }

  .custom-card-body {
    padding: 3rem 3.9rem;
    flex: 1 1 auto;
    min-height: 0.1rem;
    font-family: Montserrat, sans-serif;
    font-size: $base-font-size;
    font-weight: 500;
    line-height: 1.87;
    letter-spacing: 1.5px;
    color: $black-color;
  }

  .custom-card-footer {
    padding: 3rem;
    flex: 1 1 auto;
    min-height: 0.1rem;

    .modal-footer-confirmation {
      font-size: 1.6rem;
    }
  }

  &:not(.modal-card) {
    margin-bottom: 2rem;
  }

  &.modal-card {
    padding: 4rem;
    max-width: 80rem;

    .custom-card-header {
      padding: 0 0 1rem 0;
    }

    .custom-card-body {
      padding: 0;

      .modal-body {
        padding: 0;
      }

      .modal-footer {
        border-top: none;
      }
    }
    .custom-card-footer {
        padding: 2rem 0;
    }
  }
}
