.input-icon {
  position: relative;
}

.input-icon > i {
  position: absolute;
  display: block;
  transform: translate(0, -50%);
  top: 50%;
  pointer-events: none;
  width: 25px;
  text-align: center;
  font-style: normal;
}

.input-icon > input {
  padding-left: 25px !important;
  padding-right: 0 !important;
}

.input-icon-right > i {
  right: 5px !important;
}

.input-icon-right > input {
  padding-left: 0 !important;
  padding-right: 25px !important;
  text-align: right;
}

.m-user-image-input {
  display: flex;

  .image-input-label {
    display: flex;
    flex: 1;
    color: $black-color;
    align-items: center;
    font-weight: bold;

    &.required {
      position: relative;

      &::after {
        content: "*";
        color: $info-color;
        right: -1rem;
        top: 0;
        margin-left: 0.5rem;
      }
    }
  }

  .form-input {
    display: flex;
    flex: 1;

    .rs-uploader-trigger {
      input {
        display: none;
      }

      .rs-uploader-trigger-btn {
        font-size: $base-font-size;
        font-weight: 500;
        letter-spacing: 0.028rem;
        color: $info-color;
        text-decoration: underline;
        cursor: pointer;
      }
    }

    .rs-uploader-file-items {
      display: flex;
      justify-content: space-between;
      flex: 1 1;
      height: 5rem;
      padding: 0.5rem 1.5rem;
      border-radius: 0.4rem;
      border: 0.2rem solid $input-border-color;
      font-weight: 500;
      background: $white-color;
      align-items: center;

      .rs-uploader-file-item-text {
        display: inline-flex;
        flex: 1;
        justify-content: space-between;

        .rs-uploader-file-item-icon-wrapper {
          display: flex;
          align-items: center;
          justify-content: center;
        }

        .rs-uploader-file-item-content {
          padding-left: 1rem;
          display: inline-flex;
          align-items: center;

          .rs-uploader-file-item-size {
            padding-left: 1rem;
          }
        }
      }

      .rs-btn-close {
        display: flex;
        justify-content: center;
        align-items: center;

        svg > path {
          fill: $snippet-bg-color;
          stroke: $snippet-bg-color;
          stroke-width: 1;
          stroke-linejoin: round;
        }

        &:hover {
          color: $info-color;
        }
      }
    }

    &.empty {
      .rs-uploader-file-items {
        display: none;
      }
    }

    &.uploaded {
      .rs-uploader-trigger {
        .rs-uploader-trigger-btn {
          display: none;
        }
      }
    }
  }
}

.m-form-title {
  margin-bottom: 1.5rem;
  color: $black-color;
  line-height: 1;
  font-weight: 600;
  font-size: $ml-font-size;
  text-shadow: 0.04rem 0.44rem 0.5rem rgba(0, 2, 14, 0.1);
  letter-spacing: 0.05rem;
}

.m-form-heading{
  display: flex;
  justify-content: space-between;
  background-color: $info-color;
  font-weight: bold;
  font-size: $small-base-font-size;
  color: $white-color;

  span {
    display: flex;
    align-items: center;
    padding: 1rem;
  }
}

.onboarding-page {
  position: relative;

  .onboarding-section {
    display: flex;
    justify-content: center;
    min-height: calc(100vh - #{$auth-header-height});
    background-size: cover;
    padding: 0 2rem 4rem;

    .onboarding-header {
      .onboarding-title {
        font-size: $xxxxl-font-size;
        font-weight: 500;
        color: $black-color;
        letter-spacing: 0.1rem;
        padding-bottom: 3rem;
        text-align: center;
      }
    }

    .onboarding-content {
      padding: 4rem;
      background-color: $auth-form-background;

      .onboarding-form-title-wrapper {
        .form-title {
          margin-bottom: 2.5rem;
          color: $black-color;
          line-height: 1;
          font-weight: 600;
          font-size: $x-font-size;
          text-shadow: 0.04rem 0.44rem 0.5rem rgba(0, 2, 14, 0.1);
          letter-spacing: 0.05rem;
        }

        .form-subtitle {
          opacity: 0.6;
          letter-spacing: 0.1rem;
          text-align: left;
          font-size: $base-ms-font-size;
          font-weight: 600;
          margin-bottom: 3rem;
          white-space: pre-line;
        }

        .form-heading {
          display: flex;
          justify-content: space-between;
          background-color: $info-color;
          font-weight: bold;
          font-size: $small-base-font-size;
          color: $white-color;

          span {
            display: flex;
            align-items: center;
            padding: 1rem;
          }
        }
      }

      .onboarding-form-wrapper {
        .user-image-input {
          display: flex;

          .image-input-label {
            display: flex;
            flex: 1;
            color: $black-color;
            align-items: center;
            font-weight: bold;

            &.required {
              position: relative;

              &::after {
                content: "*";
                color: $info-color;
                right: -1rem;
                top: 0;
                margin-left: 0.5rem;
              }
            }
          }

          .form-input {
            display: flex;
            flex: 1;

            .rs-uploader-trigger {
              input {
                display: none;
              }

              .rs-uploader-trigger-btn {
                font-size: $base-font-size;
                font-weight: 500;
                letter-spacing: 0.028rem;
                color: $info-color;
                text-decoration: underline;
                cursor: pointer;
              }
            }

            .rs-uploader-file-items {
              display: flex;
              justify-content: space-between;
              flex: 1 1;
              height: 5rem;
              padding: 0.5rem 1.5rem;
              border-radius: 0.4rem;
              border: 0.2rem solid $input-border-color;
              font-weight: 500;
              background: $white-color;
              align-items: center;

              .rs-uploader-file-item-text {
                display: inline-flex;
                flex: 1;
                justify-content: space-between;

                .rs-uploader-file-item-icon-wrapper {
                  display: flex;
                  align-items: center;
                  justify-content: center;
                }

                .rs-uploader-file-item-content {
                  padding-left: 1rem;
                  display: inline-flex;
                  align-items: center;

                  .rs-uploader-file-item-size {
                    padding-left: 1rem;
                  }
                }
              }

              .rs-btn-close {
                display: flex;
                justify-content: center;
                align-items: center;

                svg > path {
                  fill: $snippet-bg-color;
                  stroke: $snippet-bg-color;
                  stroke-width: 1;
                  stroke-linejoin: round;
                }

                &:hover {
                  color: $info-color;
                }
              }
            }

            &.empty {
              .rs-uploader-file-items {
                display: none;
              }
            }

            &.uploaded {
              .rs-uploader-trigger {
                .rs-uploader-trigger-btn {
                  display: none;
                }
              }
            }
          }
        }

        .add-button-wrapper {
          display: flex;
          align-items: center;

          .btn-add {
            padding-left: 0.5rem;
            text-decoration: underline;
            color: $info-color;
            font-weight: 500;
            font-size: $base-font-size;
            cursor: pointer;
            letter-spacing: 0.1rem;
            background-color: transparent;
          }

          span {
            color: $info-color;
            font-size: $ml-font-size;
          }
        }

        .additional-information {
          margin-top: 1rem;
          font-size: $small-font-size;
          font-weight: 600;
          color: rgba($black-color, 0.36);
          text-align: left;
          letter-spacing: 0.036rem;
        }
      }

      .onboarding-navigation {
        display: flex;
        justify-content: space-between;
        margin-top: 3rem;
      }
    }

    .onboarding-awaiting-info-container {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      padding: 4rem;
      background: $auth-form-background;

      .onboarding-awaiting-info {
        margin-bottom: 2rem;
        color: $gray-text-color;
        font-weight: 600;
        text-align: center;
        letter-spacing: 0.1rem;
      }
    }
  }

  .btn-logout {
    position: absolute;
    right: 1rem;
    top: 6rem;

    &:hover {
      color: $primary-color;
    }
  }
}

.profile-form,
.onboarding-form {
  color: $input-text-color-gray;

  &.onboarding-table {
    .row {
      margin: 0;

      .onboarding-table-cell {
        justify-content: center;
        margin: 0;
        padding: 0;
        background: $white-color;
        border: 0.1rem solid $auth-form-background;

        &.checkbox-container {
          justify-content: space-between;
          padding: 0 2rem;
        }

        &.input-readonly {
          opacity: 0.8;
          pointer-events: none;
          cursor: text;

          .input {
            font-weight: bold;
            border: none;
            color: rgba($black-color, 0.36);
            background: transparent;
          }
        }

        &.cell-input {
          padding: 0.5rem;

          &.invalid {
            .input-container {
              .input {
                border-color: $error-block-color;
              }
            }
          }

          .error-block {
            display: none;

            .error-element {
              display: none;
            }
          }
        }

        &.hide-label {
          .form-label {
            display: none;
          }
        }

        &.textarea-mask {
          background: transparent;

          textarea {
            background: transparent;
            border: none;
            font-weight: 500;
          }
        }

        &.cell-label {
          padding: 0 1rem;
        }

        &.bg-transparent {
          background: transparent;

          .input-container {
            .input {
              background: transparent;
            }
          }
        }
      }
    }
  }

  // Krok pierwszy onboardingu style labela nad polami o koncie bankowym
  .onboarding-info-label {
    .form-label {
      margin-top: 1rem;
      color: $input-text-color-gray;
      font-size: $small-base-font-size;
      font-weight: 600;
      letter-spacing: 0.039rem;
    }
  }

  .checkbox-container {
    flex-direction: row-reverse;
    align-items: center;
    justify-content: flex-end;
    margin: 0;

    .checkbox-wrapper {
      width: 2.1rem;
      height: 2.1rem;
      margin: 0;
      padding: 0;

      .checkbox-checkmark {
        top: 0;
        bottom: 0;

        &:after {
        }
      }
    }

    .form-label {
      margin: 0 0 0 2rem;
    }
  }

  .help-range-form-group {
    margin: 0;

    .form-label {
      margin: 0.5rem 0;
      padding: 0.75rem 0;
      color: $black-color;
    }

    .checkbox-row {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      padding: 0 1.5rem;

      &:nth-of-type(odd) {
        background-color: $white-color;
      }
    }
  }

  .colliding-dates-form-group {
    margin: 0;

    .form-label {
      margin: 0;
      padding: 0.75rem 0.5rem;
      color: $black-color;
    }

    .checkbox-row {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: flex-start;
      padding: 0 1.5rem;

    }
  }

  .fieldset {
    margin-bottom: 0.5rem;

    .fieldset-legend {
      margin: 0.5rem 0;
      padding: 0.75rem 1.5rem;
      font-weight: 700;
      color: $black-color;
      font-size: $base-ms-font-size;
    }

    .default-fieldset {
      margin: 0 0 1rem;

      &.maturity-fieldset {
        .checkbox-fieldset-container {
          flex-basis: 50%;
        }
      }

      .checkbox-fieldset-container {
        flex-direction: row;
        flex-basis: 33.3333%;
        align-items: center;
        justify-content: space-between;
        padding: 1rem 1.5rem;
        margin: 0;
        background-color: $white-color;
        border: thin solid $auth-form-background;

        .form-label {
          margin: 0;
          font-size: $small-base-font-size;
          letter-spacing: 0.039rem;
        }
      }
    }
  }

  .study-year-control {
    align-items: center;

    .custom-number-input-wrapper {
      max-width: 12rem;
    }
  }

  .row-study-information {
    position: relative;
    display: flex;
    flex-direction: row;

    .form-label {
      color: $black-color;
      display: flex;
      flex: 1;
    }

    .checkbox-container {
      display: flex;
      flex: 1;
      margin-bottom: 1.5rem;
    }
  }

  .m-row-study-information {
    position: relative;
    display: flex;
    flex-direction: row;
    box-shadow: 0 0 2px 0 #000000;
    padding: 15px 10px 10px 10px;
    border-radius: 5px;
    margin-bottom: 10px;

    .tooltip-wrapper {
      width: 100%;
    }

    .form-label {
      color: $black-color;
      display: flex;
      flex: 1;
    }

    .checkbox-container {
      display: flex;
      flex: 1;
      margin-bottom: 1.5rem;
    }
  }

  .input {
    display: flex;
    flex: 1;
    height: 5rem;
    padding: 0.5rem 1.5rem;
    border-radius: $button-border-radius;
    border: 0.2rem solid $input-border-color;
    font-weight: 500;
    background: $white-color;

    &.grey-on-disable {
      &:disabled{
        background: #f0f0f0 !important;
      }
    }

    &[type="file"] {
      border: none;
      background-color: transparent;
      padding: 0;
      margin: 0;
      color: transparent;

      &::-webkit-file-upload-button {
        visibility: hidden;
      }

      &::before {
        content: "Dodaj zdjęcie z dysku";
        display: flex;
        align-items: center;
        height: 100%;
        text-align: left;
        letter-spacing: 0.014rem;
        color: $info-color;
        text-decoration: underline;
        font-weight: 500;
        font-size: $small-base-font-size;
      }
    }
  }

  .custom-number {
    justify-content: center;

    .custom-number-input-wrapper {
      margin: 0;

      &.disabled {
        opacity: 0.6;
        pointer-events: none;
        cursor: not-allowed;
      }
    }
  }


  .form-row {
    align-items: center;
    flex-direction: row;
    margin-bottom: 1rem;

    &-edit {
      flex-direction: column;
      align-items: unset;
    }

    &.required {
      position: relative;

      .form-label {
        color: $black-color;
        position: relative;

        &::after {
          content: "*";
          color: $info-color;
          right: -1rem;
          top: 0;
          margin-left: 0.5rem;
        }
      }

      .error-block {
        position: absolute;
        right: 4rem;
        bottom: 3.5rem;
        align-items: center;
        flex-direction: row;

        &:before {
          content: "!";
          position: absolute;
          top: 0;
          left: 0;
          display: flex;
          align-items: center;
          justify-content: center;
          width: 2rem;
          height: 2rem;
          border-radius: 50%;
          border: 0.2rem solid $primary-color;
          color: $primary-color;
          font-weight: 600;
        }

        .error-element {
          display: none;
        }
      }
    }

    &.study-year-control {
      flex-direction: row-reverse;
      padding: 0;
    }

    &.no-option-checkbox {
      justify-content: flex-end;

      .checkbox-container {
        width: 50%;
      }
    }

    .basic-single {
      flex: 1;
    }

    .form-label {
      flex: 1;
      margin: 0;
    }

    .input-container {
      flex: 1;
    }

    .react-datepicker-host {
      flex: 1;

      .react-datepicker__header__dropdown {
        display: flex;
        justify-content: center;

        .react-datepicker__month-dropdown-container .react-datepicker__month-select,
        .react-datepicker__year-dropdown-container .react-datepicker__year-select {
          height: 2rem;
          cursor: pointer;
          font-family: Montserrat, sans-serif;

          &:focus {
            outline: none;
          }
        }
      }

      input {
        border-radius: $button-border-radius;
        border: 0.2rem solid $input-border-color;
      }
    }

    .basic-multi-select {
      flex: 1;

      .select__control {
        border-radius: $button-border-radius;
        border: 0.2rem solid $input-border-color;
      }
    }

    .textarea-container {
      flex: 1;

      textarea {
        padding: 0.5rem 1.5rem;
        font-weight: 500;
        resize: vertical;
        border-radius: $button-border-radius;
        border: 0.2rem solid $input-border-color;
      }
    }

    .radio-control {
      flex: 1;
      display: flex;
      flex-wrap: wrap;

      .radio-wrapper {
        display: flex;
        align-items: center;
        flex-wrap: wrap;

        .radio-input,
        .radio-checkmark {
          display: none;
        }

        .radio-label {
          margin: 0 0.3rem 0.3rem 0;
          padding: 0.9rem 1.2rem;
          border: 0.2rem solid $input-border-color;
          border-radius: 2rem;
          color: $input-text-color-gray;
          background: $white-color;
          font-size: $small-base-font-size;
          font-weight: 500;
          white-space: nowrap;
          letter-spacing: normal;

          &:hover {
            cursor: pointer;
          }
        }

        .radio-input:checked ~ .radio-label {
          background: $info-color;
          color: $white-color;
          border: 0.2rem solid $info-color;
        }
      }
    }
  }
}

@media only screen and (max-width: $breakpoint-medium) {
  .onboarding-page {
    .onboarding-section {
      .onboarding-content {
        .onboarding-form-wrapper {
          .onboarding-form {
            .form-row {
              display: block;

              &.study-year-control {
                flex-direction: column;
                padding: 0 0.75rem;
                display: flex;
                align-items: flex-start;
              }
            }

            .fieldset {
              .default-fieldset {
                .checkbox-fieldset-container {
                  flex-basis: 50%;
                }
              }
            }
          }

          .add-button-wrapper {
            justify-content: flex-end;
          }
        }
      }
    }
  }
}


@media only screen and (max-width: $breakpoint-xsmall) {
  .onboarding-page {
    .btn-logout {
      top: 1rem;
    }

    .onboarding-section {
      .onboarding-header {
        .onboarding-title {
          font-size: $xxxl-font-size;
        }
      }

      .onboarding-content {
        padding: 2rem;

        .onboarding-form-wrapper {
          .form-heading {
            flex-direction: column;
          }

          .onboarding-form {
            margin-top: 2rem;

            .form-row {
              .form-label {
                margin-bottom: 1rem;
              }
            }

            .onboarding-table {
              .row {
                flex-direction: column;
                flex-wrap: nowrap;
              }
            }

            .fieldset {
              .default-fieldset {
                .checkbox-fieldset-container {
                  flex-basis: 100%;
                }
              }
            }
          }
        }
      }
    }
  }
}
