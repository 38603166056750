.navbar {
  display: block;
  //z-index: 1028;
  padding: 0;
  transition: all 0.3s ease-in-out;
  min-width: $menu-width;
  position: relative;
  background: linear-gradient(to bottom right, $navigation-gradient);
  color: $white-color;

  &:before {
    content: "";
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    background: url($navbarBackgroundImageBase64) no-repeat center 50rem;
  }

  .sidebar-content {
    position: fixed;

    .sidebar-logo-container {
      height: 5.2rem;
      margin-top: 2rem;
      padding-left: 4rem;
      display: flex;
    }

    ul {
      list-style: none;

      &.sidebar-menu {
        margin-top: 2rem;
        width: 33rem;
      }

      li {
        &.nav-item {
          padding: 0.5rem 0 0.5rem 2rem;

          &:hover {
            color: transparentize($theme-second-color, 0.9);
            background: transparent;
          }
        }

        .nav-link {
          display: flex;
          align-items: center;
          color: $white-color;
          font-size: 1.6rem;
          font-family: Montserrat, sans-serif;
          font-weight: 500;
          position: relative;
          transition: none;

          .nav-link-text {
            overflow: hidden;
            font-size: $base-font-size;

            &.collapsed {
              transform: rotateY(-90deg);
              transform-origin: 0 0;
              display: none;
            }
          }

          &.active {
            font-weight: 600;
            cursor: initial;
            color: $black-color;
            background-color: #fff;
            border-bottom-left-radius: 3rem;
            border-top-left-radius: 3rem;
            position: relative;

            &::after {
              content: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABcAAAAXCAMAAADX9CSSAAAAAXNSR0IB2cksfwAAAAlwSFlzAAALEwAACxMBAJqcGAAAAJ9QTFRFAAAAL6/oT7rpbsjxn9ry1/H6NrLr/v/9iNHx//7/4PL+/v//acnu////3vH4///9i9TzSbvp+fv//f/+LrDo1/L5xOn7/v/7/v78teP3/v7+/v7/LbLovub4/v3+T7vu3vD6jtfy9vv9/f7/c8zu3vH7//7+//3+QrjqkNby4PT7/P3+M7HnQLfqVMDsfMzvotr22fH7/P7////7/f3+yqLKtAAAADV0Uk5TAHD/////4P////////////////9g/////////1D///////////////////+w//////////9A9YYmAAAAkElEQVR4nK3PyQ7CMAwE0LIGYwYDLVsClH0rO/z/t9FyQChybszxyR7LUfSXlAJeDngl4NWA13SuG90bpHuTVW4RVG+LaNxhaPPdXqz1JH0ymg+GIyg9Y0tw7PtkOgND/J50ziCI74slrwif/Oh647Yi2Jl85ev7wzEDbP4RLFDcPZ0v19v94UieTAVacPx6A0trCTuqcONBAAAAAElFTkSuQmCC);
              position: absolute;
              right: 0;
              height: 2.2rem;
              top: -2.3rem;
            }

            &::before {
              content: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABcAAAAXCAMAAADX9CSSAAAAAXNSR0IB2cksfwAAAAlwSFlzAAALEwAACxMBAJqcGAAAALpQTFRFYMTrbsnves3yjdXysuP23PL88fr9+Pv9//7//////v/+///9/v/9/v7+/f39+/v7AAAAPrjogM/xyu388vv8/f79SrvqseL1/P////78/v//Sbvsx+n6+Pz+//3+/P/7gdLw7/r8/v7/UL3q4/P5//79/v78OLXr2PH8N7To4/P7SLrr8vj+bsry/P7/ruT1//7+M7Dn8fn+/P/9hdHx/f//8ff9nNrzR7nq6fb+ruL0d8zxVbzrNLLowEpvmAAAAD50Uk5T/////////////////////wD////////////////////////////////w/////////7D//////////////8AKbRS8AAAAoElEQVR4nHXM2RLBUBCE4dhnTNAI4iDWxL7v2/u/FlJUIef8l191tWXF4olkKp0hzpLYHJbLFyyEFUtlp8Is/Ontz6o15wWuXWdFXw40mi3xWIil/eNAp8vUc5n4z9Ef+CpgP+IYjpg8pSKO8YRFontgOmPpaRzzBYnOsVwp0vl6E2x1jt3e0zoO2h/gqPR+8vWOs8EvBr8a/Gbwu8Fh8gciFSOtBjxoQAAAAABJRU5ErkJggg==);
              position: absolute;
              right: 0;
              height: 2.2rem;
              bottom: -2.2rem;
            }
          }

          &:not(.active):hover {
            font-weight: 400;
            color: $black-color;
            cursor: pointer;
          }
        }
      }
    }

    &.navbar-collapsed {
      width: $menu-collapsed-width;
      transition: all 0.3s ease-in-out;

      .submenu {
        display: none !important;
      }

      .nav-link {
        color: $base-text-color;
        font-size: 1.6rem;

        &:after {
          display: none;
        }
      }
    }
  }
}

.nav-link-text {
  margin-left: 0.5rem;

  &.collapsed {
    transform: rotateY(-90deg);
    transform-origin: 0 0;
    display: none;
    transition: all 0.3s ease-in-out;
  }
}

.has-menu {
  position: relative;
  width: 100%;
  color: #535763;
  text-align: left;
  font-weight: 400;
  margin: 0.5rem 0 0;
  padding: 0.5rem 1rem;
  display: block;
  font-size: 1.6rem;

  &.nav-link-trigger {
    .nav-link {
      &:after {
        transform: rotate(90deg);
      }
    }
  }

  &.side-menu-trigger.active {
    > .submenu {
      display: block;
      padding: 0.5rem 0;
      margin-left: 2.5rem;
    }
  }

  .nav-link {
    &:after {
      content: "\e844";
      font-family: 'feather';
      position: absolute;
      top: 1.75rem;
      right: 2rem;
      transition: all 0.3s ease-in-out;
    }
  }

  .submenu {
    display: none;
    position: relative;
    overflow: hidden;

    &:after {
      content: "";
      font-family: unset;
      position: absolute;
      top: 0.5rem;
      left: 0;
      bottom: 1.8rem;
      width: 0.1rem;
      background: transparentize($secondary-color, .8);
    }

    li {
      position: relative;

      &:after {
        content: "";
        position: absolute;
        top: 1.75rem;
        left: 0.1rem;
        width: 1.5rem;
        height: .1rem;
        background: transparentize($secondary-color, .8);
      }

      > a {
        text-align: left;
        padding: 0 1.75rem;
        margin: 0.5rem 0 0 0;
        display: block;
        font-weight: 400;
        font-size: $base-font-size;

        &:before, &:after {
          display: none;
        }
      }
    }
  }
}

.nav-link-icon {
  font-size: 1.6rem;
  min-width: 3rem;
  height: 3rem;
  display: flex;
  align-items: center;
  justify-content: center;
}


@media only screen and (max-width: $breakpoint-medium) {
  .navbar {
    margin-left: -#{$menu-width};
    position: absolute;
    height: 100%;
    box-shadow: none;

    &.mob-open {
      margin-left: 0;
    }
  }
}
