.btn {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  font-weight: 500;
  text-align: center;
  vertical-align: middle;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  background-color: transparent;
  text-decoration: none;
  border: none;
  padding: 0 1rem;
  font-size: $base-font-size;
  line-height: 1.5;
  height: $button-height;
  -webkit-transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out;
  transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out;
  // text-transform: uppercase;
  border-radius: $button-border-radius;

  i {
    margin-left: 1rem;
    font-size: $base-font-size;
    transition: margin-left 0.3s;
  }

  &:not(:disabled):not(.disabled) {
    cursor: pointer;
  }

  &:visited {
    text-decoration: none;
  }

  &.disabled {
    opacity: 0.65;
  }

  &.btn-theme {
    border-radius: $border-radius;
    display: flex;
    justify-content: center;
    position: relative;
    background-color: $info-color;
    color: $white-color;
    box-shadow: 0.34rem 0.37rem 1.35rem 0 rgba(0, 0, 0, 0.1);
    text-transform: none;
    padding: 2.4rem 3.5rem 2.4rem 2.5rem;
    letter-spacing: 0.039rem;

    &:hover,
    &:focus,
    &:active,
    &:hover:focus,
    &:hover:active,
    &:focus:active,
    &:hover:focus:active,
    &:not(:disabled):not(.disabled):active{
      background: darken($info-color, 10%);
      box-shadow: none;
    }

    &.btn-start-chat {
      &::after {

      }
    }
    &.competence {
        padding: 1.3rem 3rem 1.3rem 1.5rem;
        height: unset;
        box-shadow: -0.6rem 0.8rem 2.7rem 0 rgba(0, 0, 0, 0.1);
        margin-bottom: 1rem;

        &::after {
            right: 1.5rem;
        }
    }

    &::after {
      display: flex;
      flex: 1;
      justify-content: center;
      align-items: center;
      opacity: 0.6;
      height: inherit;
      content: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAYAAAALBAMAAACufOGlAAAAAXNSR0IB2cksfwAAAAlwSFlzAAALEwAACxMBAJqcGAAAACFQTFRF////AAAA////////////////////////////////////TiSQqAAAAAt0Uk5TIADw7zCg//ZX3PdkMzKtAAAAMElEQVR4nGNgEBRkUHYUZAgzEWQQTXZkEAyrYBCUTALSKxhEkxwZwlQEGZSA8kB1AIh8Bj/Xm+WHAAAAAElFTkSuQmCC');
      position: absolute;
      right: 2rem;
    }
  }

  &.btn-no-arrow {
    padding-left: 2.5rem !important;
    padding-right: 2.5rem !important;
    &::after {
      content: none !important;
    }
  }

  &.btn-theme-arrowless {
    border-radius: $border-radius;
    display: flex;
    justify-content: center;
    position: relative;
    background-color: $info-color;
    color: $white-color;
    box-shadow: 0.34rem 0.37rem 1.35rem 0 rgba(0, 0, 0, 0.1);
    text-transform: none;
    padding-top: 2.4rem;
    padding-bottom: 2.4rem;
    padding-left: 2.5rem !important;
    padding-right: 2.5rem !important;

    letter-spacing: 0.039rem;

    &:hover,
    &:focus,
    &:active,
    &:hover:focus,
    &:hover:active,
    &:focus:active,
    &:hover:focus:active,
    &:not(:disabled):not(.disabled):active{
      background: darken($info-color, 10%);
      box-shadow: none;
    }

    &.btn-start-chat {
      &::after {

      }
    }
    &.competence {
      padding: 1.3rem 3rem 1.3rem 1.5rem;
      height: unset;
      box-shadow: -0.6rem 0.8rem 2.7rem 0 rgba(0, 0, 0, 0.1);
      margin-bottom: 1rem;

      &::after {
        right: 1.5rem;
      }
    }

    &::after {
      display: flex;
      flex: 1;
      justify-content: center;
      align-items: center;
      opacity: 0.6;
      height: inherit;
      position: absolute;
      right: 2rem;
    }
  }

  &.btn-radio-active {

  }

  &.btn-radio-inactive {

  }

  &.btn-theme-no-outline {
    color: $info-color;
    padding: 0;

    &:hover,
    &:focus,
    &:active,
    &:hover:focus,
    &:hover:active,
    &:focus:active,
    &:hover:focus:active,
    &:not(:disabled):not(.disabled):active {
      text-decoration: underline;
      box-shadow: none;
    }

    &.btn-tooltip {
      font-size: $small-font-size;
      text-decoration: underline;
    }
  }

  &.btn-back {
    padding: 2.5rem 2rem;
    background-color: transparent;
    box-shadow: none;
    color: $black-color;
    &::after {
      right: unset;
      opacity: 1;
      left: 0;
      content: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAgAAAAMCAMAAABoQAyvAAAAAXNSR0IB2cksfwAAAAlwSFlzAAALEwAACxMBAJqcGAAAAD9QTFRF9vn86OvukZKUmpye6ezvjY+Qe3x+j5GSjpCRi42O6Ort6u3wioyN5+nslJWXiYuM5efq6+7x5ujrk5SWnJ6gdEqZVgAAAD1JREFUeJxjYEAGjEzMYJqFlY0dTHOwcXIBaW4ONh5ekAAflAYy+AXADEF2Nn4hMIsbweKAaAcaICyCMB0ATAoBlz7n7mkAAAAASUVORK5CYII=')
    }

    &:hover,
    &:focus,
    &:active,
    &:hover:focus,
    &:hover:active,
    &:focus:active,
    &:hover:focus:active,
    &:not(:disabled):not(.disabled):active{
      background: transparent;
      text-decoration: underline;
    }
  }

  &.btn-rounded {
    border-radius: $border-radius;
    box-shadow: -0.6rem 0.8rem 2.7rem 0 rgba(0, 0, 0, 0.1);
  }


  &.btn-secondary-theme {
    color: $white-color;
    background: $theme-second-color;
    border: 0.1rem solid $theme-second-color;

    &:hover,
    &:focus,
    &:active,
    &:hover:focus,
    &:hover:active,
    &:focus:active,
    &:hover:focus:active,
    &:not(:disabled):not(.disabled):active{
      color: $white-color;
      background: $theme-second-color;
      border: 0.1rem solid $theme-second-color;
      opacity: 0.75;
      box-shadow: 0 0 0 0.2rem lighten($theme-second-color, 50%);
    }
  }

  &.btn-theme-outline {
    color: $black-color;
    border: 0.1rem solid $theme-color;

    &:hover,
    &:focus,
    &:active,
    &:hover:focus,
    &:hover:active,
    &:focus:active,
    &:hover:focus:active {
      color: $black-color;
      border: 0.1rem solid $theme-color;
      opacity: 0.75;
      box-shadow: 0 0 0 0.2rem lighten($theme-color, 50%);
    }

    &.competence {
        padding: 1.2rem 3rem 1.2rem 1.5rem;
        height: unset;
        position: relative;
        box-shadow: -0.6rem 0.8rem 2.7rem 0 rgba(0, 0, 0, 0.1);
        margin-bottom: 1rem;

        &::after {
            display: flex;
            flex: 1;
            justify-content: center;
            align-items: center;
            opacity: 0.6;
            height: inherit;
            content: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAgAAAAMCAMAAABoQAyvAAAAAXNSR0IB2cksfwAAAAlwSFlzAAALEwAACxMBAJqcGAAAAD9QTFRF9vn86OvukZKUmpye6ezvjY+Qe3x+j5GSjpCRi42O6Ort6u3wioyN5+nslJWXiYuM5efq6+7x5ujrk5SWnJ6gdEqZVgAAAD1JREFUeJxjYEAGjEzMYJqFlY0dTHOwcXIBaW4ONh5ekAAflAYy+AXADEF2Nn4hMIsbweKAaAcaICyCMB0ATAoBlz7n7mkAAAAASUVORK5CYII=');
            transform: rotate(180deg);
            position: absolute;
            right: 1rem;
        }
    }
  }

  &.btn-primary {
    padding-right: 0;
    background: $white-color;
    border: 0.1rem solid $theme-color;
    color: $black-color;

    &::after {
      content: "\e844";
      font-family: "feather";
      background: $theme-color;
      height: 3.3rem;
      width: 3rem;
      color: white;
      font-size: 2.4rem;
      font-weight: 500;
      display: flex;
      justify-content: center;
      align-items: center;
      margin-left: 1rem;
    }

    &:hover,
    &:focus,
    &:active,
    &:hover:focus,
    &:hover:active,
    &:focus:active,
    &:hover:focus:active,
    &:not(:disabled):not(.disabled):active {
      color: $black-color;
      border: 0.1rem solid $theme-color;
      opacity: 0.75;
      background-color: $white-color;
      box-shadow: 0 0 0 0.2rem lighten($theme-color, 50%);
    }

    &:disabled {
      border-color: $theme-second-color;

      &::after {
        background-color: $theme-second-color;
      }
    }
  }

  &.btn-primary-outline {
    color: $primary-color;
    border: 0.1rem solid $primary-color;

    &:hover,
    &:focus,
    &:active,
    &:hover:focus,
    &:hover:active,
    &:focus:active,
    &:hover:focus:active {
      color: $primary-color;
      border: 0.1rem solid $primary-color;
      opacity: 0.75;
      box-shadow: 0 0 0 0.2rem lighten($primary-color, 50%);
    }
  }

  &.btn-danger {
    color: $white-color;
    background: $danger-color;
    border: 0.1rem solid $danger-color;

    &:hover,
    &:focus,
    &:active,
    &:hover:focus,
    &:hover:active,
    &:focus:active,
    &:hover:focus:active,
    &:not(:disabled):not(.disabled):active{
      color: $white-color;
      background: $danger-color;
      border: 0.1rem solid $danger-color;
      opacity: 0.75;
      box-shadow: 0 0 0 0.2rem lighten($danger-color, 50%);
    }
  }

  &.btn-danger-outline {
    color: $danger-color;
    border: 0.1rem solid $danger-color;
    border-radius: $border-radius;
    box-shadow: 0.34rem 0.37rem 1.35rem 0 rgba(0, 0, 0, 0.1);
    text-transform: none;
    padding: 2.3rem 2.5rem;
    letter-spacing: 0.039rem;

    &:hover,
    &:focus,
    &:active,
    &:hover:focus,
    &:hover:active,
    &:focus:active,
    &:hover:focus:active {
      color: $danger-color;
      border: 0.1rem solid $danger-color;
      opacity: 0.75;
      box-shadow: 0 0 0 0.2rem lighten($danger-color, 50%);
    }
  }

  &.btn-info {
    color: $white-color;
    background: $info-color;
    border: 0.1rem solid $info-color;

    &:hover,
    &:focus,
    &:active,
    &:hover:focus,
    &:hover:active,
    &:focus:active,
    &:hover:focus:active,
    &:not(:disabled):not(.disabled):active{
      color: $white-color;
      background: $info-color;
      border: 0.1rem solid $info-color;
      opacity: 0.75;
      box-shadow: 0 0 0 0.2rem lighten($info-color, 50%);
    }
  }

  &.btn-info-outline {
    color: $info-color;
    border: 0.1rem solid $info-color;

    &:hover,
    &:focus,
    &:active,
    &:hover:focus,
    &:hover:active,
    &:focus:active,
    &:hover:focus:active {
      color: $info-color;
      border: 0.1rem solid $info-color;
      opacity: 0.75;
      box-shadow: 0 0 0 0.2rem lighten($info-color, 50%);
    }
  }

  &.btn-success {
    color: $white-color;
    background: $success-color;
    border: 0.1rem solid $success-color;

    &:hover,
    &:focus,
    &:active,
    &:hover:focus,
    &:hover:active,
    &:focus:active,
    &:hover:focus:active,
    &:not(:disabled):not(.disabled):active{
      color: $white-color;
      background: $success-color;
      border: 0.1rem solid $success-color;
      opacity: 0.75;
      box-shadow: 0 0 0 0.2rem lighten($success-color, 50%);
    }
  }

  &.btn-success-outline {
    color: $success-color;
    border: 0.1rem solid $success-color;

    &:hover,
    &:focus,
    &:active,
    &:hover:focus,
    &:hover:active,
    &:focus:active,
    &:hover:focus:active {
      color: $success-color;
      border: 0.1rem solid $success-color;
      opacity: 0.75;
      box-shadow: 0 0 0 0.2rem lighten($success-color, 50%);
    }
  }

  &.btn-warning {
    color: $white-color;
    background: $warning-color;
    border: 0.1rem solid $warning-color;

    &:hover,
    &:focus,
    &:active,
    &:hover:focus,
    &:hover:active,
    &:focus:active,
    &:hover:focus:active,
    &:not(:disabled):not(.disabled):active{
      color: $white-color;
      background: $warning-color;
      border: 0.1rem solid $warning-color;
      opacity: 0.75;
      box-shadow: 0 0 0 0.2rem lighten($warning-color, 50%);
    }
  }

  &.btn-warning-outline {
    color: $warning-color;
    border: 0.1rem solid $warning-color;

    &:hover,
    &:focus,
    &:active,
    &:hover:focus,
    &:hover:active,
    &:focus:active,
    &:hover:focus:active {
      color: $warning-color;
      border: 0.1rem solid $warning-color;
      opacity: 0.75;
      box-shadow: 0 0 0 0.2rem lighten($warning-color, 50%);
    }
  }

  &.btn-add-subject,
  &.btn-remove-subject,
  &.btn-custom-duration {
    width: 100%;
    background: transparent;
    color: $info-color;
    opacity: 1;
    text-decoration: underline;
    border: none;
    padding: 1rem 0;
    box-shadow: none;
    justify-content: flex-start;

    &:hover,
    &:focus,
    &:active,
    &:hover:focus,
    &:hover:active,
    &:focus:active,
    &:hover:focus:active {
      cursor: pointer;
      transform: none;
      background: $white-color;
      border: none;
      box-shadow: none;
      color: $info-color;
      outline: none;
    }

    &:hover {
      transform: none;

      i {
        margin-left: 0;
      }
    }

    &.event {
      color: $datepicker-header-color;
      justify-content: center;
      text-decoration: none;
    }
  }

  &.btn-remove-subject {
    position: absolute;
    right: -2rem;
    top: 0.75rem;
    width: unset;
    text-decoration: none;
    font-size: $m-font-size;

    &:hover {
      font-weight: 600;
    }
  }

  &.btn-remove-study-subject {
    position: absolute;
    right: 4rem;
    top: 0;
    width: unset;
    text-decoration: none;
    font-size: $m-font-size;
    background-color: transparent;

    &:hover {
      font-weight: 600;
      background-color: transparent;
    }

    &:after {
      display: none;
    }
  }

  &.btn-no-outline {
    border: none;
    color: $grey-text-color;

    &:hover,
    &:focus,
    &:active,
    &:hover:focus,
    &:hover:active,
    &:focus:active,
    &:hover:focus:active {
      opacity: 0.75;
    }
  }

  &.disabled,
  &:disabled {
    opacity: 0.25;
    cursor: not-allowed;
  }

  &.btn-small {
    padding: 1.75rem 2.5rem 1.75rem 1.25rem;
    font-size: $small-font-size;
    line-height: 1;

    &::after {
      right: 1rem;
    }
  }

  &.btn-action {
    color: $btn-action-color;
    border: 0.1rem solid $btn-action-border-color;
    height: 3rem;

    .feather {
      margin-right: 0;
      font-size: $base-font-size;
    }

    &.not-active {
      pointer-events: none;
      opacity: 0.6;
      cursor: not-allowed;
    }

    &:hover,
    &:focus,
    &:active,
    &:hover:focus,
    &:hover:active,
    &:focus:active,
    &:hover:focus:active {
      color: $card-border-bottom-color;
      border: 0.1rem solid $card-border-bottom-color;
      opacity: 0.75;
      box-shadow: 0 0 0 0.2rem lighten($card-border-bottom-color, 50%);
    }
    &.borderless {
      border: none;
      &:hover,
      &:focus,
      &:active,
      &:hover:focus,
      &:hover:active,
      &:focus:active,
      &:hover:focus:active {
        border: none;
      }
    }
    &[data-descr] {
      position: relative;

      &:hover,
      &:focus {
        &::after {
          content: attr(data-descr);
          position: absolute;
          top: -3.3rem;
          padding: 0.3rem 1.2rem;
          border: 0.1rem $info-color solid;
          background-color: $white-color;
          border-radius: $border-radius;
          color: $black-color;
          font-weight: 600;
          font-size: $xs-font-size;
          white-space: nowrap;
          z-index: 1;
          opacity: 1;
        }

        &::before {
          content: "";
          width: 0.75rem;
          height: 0.75rem;
          background-color: $white-color;
          transform: rotate(45deg);
          position: absolute;
          top: -1.3rem;
          border-right: 0.1rem solid $info-color;
          border-bottom: 0.1rem solid $info-color;
          z-index: 2;
          opacity: 1;
        }
      }
    }
  }

  .disabled {
    opacity: 0.5;
    pointer-events: none;
  }

  .feather {
    margin-right: 1rem;
  }

  &.btn-icon {
    &:hover {
      opacity: 0.4;
    }
  }
}
