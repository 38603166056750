.tooltip-wrapper {
  display: inline-block;
  position: relative;

  .tooltip-content {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    padding: 1rem;
    color: $black-color;
    background: $white-color;
    border: 0.2rem $info-color solid;
    border-radius: 0.4rem;
    font-size: $base-font-size;
    font-family: Montserrat, sans-serif;
    font-weight: 500;
    line-height: 1.5;
    z-index: 100;
    max-width: 50rem;
    width: 50rem;
    white-space: pre-line;

    &:before {
      content: "";
      left: 50%;
      border: solid transparent;
      height: 0;
      width: 0;
      position: absolute;
      pointer-events: none;
      border-width: 0.6rem;
      margin-left: calc(0.6rem * -1);
    }

    &.top::before {
      top: 100%;
      border-top-color: $info-color;
    }

    &.right {
      top: 50%;
      transform: translateX(0) translateY(-50%);
    }

    &.right::before {
      left: calc(0.6rem * -1);
      top: 50%;
      transform: translateX(0) translateY(-50%);
      border-right-color: $info-color;
    }

    &.bottom::before {
      bottom: 100%;
      border-bottom-color: $info-color;
    }

    &.left {
      left: auto;
      top: 50%;
      transform: translateX(0) translateY(-50%);

      &.consultation-tooltip {
        min-width: 20rem;
        z-index: 999;
        transform: translateX(-100%) translateY(-50%);
      }
    }

    &.left::before {
      left: auto;
      right: calc(0.6rem * -2);
      top: 50%;
      transform: translateX(0) translateY(-50%);
      border-left-color: $info-color;
    }
  }
}
