form {
  .form-row {
    .col-25, .col-33, .col-50, .col-66, .col-75, .col-100 {
      float: left;
      padding: 0.5rem;
    }

    .col-25 {
      width: 25%;
    }

    .col-33 {
      width: 33.3333%;
    }

    .col-50 {
      width: 50%;
    }

    .col-66 {
      width: 66.66666%;
    }

    .col-75 {
      width: 75%;
    }

    .col-100 {
      width: 100%;
    }

    &.custom-row {
      border-bottom: 0.1rem solid #ccc;
      margin-bottom: 1rem;
      padding-bottom: 1rem;
    }
  }
}
