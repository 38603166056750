.range-control-wrapper {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  width: 100%;

  .label-container {
    display: flex;
    flex: 1;
    justify-content: space-between;
    align-items: flex-end;

    .label {
      font-size: $base-font-size;
      font-weight: bold;
    }

    .output {
      display: flex;
      margin-right: 1rem;
      font-weight: 700;
      font-size: $small-font-size;

      .output-unit {
        margin-left: 0.5rem;
      }
    }
  }

  .track {
    height: 3.6rem;
    display: flex;
    width: 100%;
    margin: 0 1rem;

    .track-background {
      height: 0.5rem;
      width: 100%;
      border-radius: $border-radius;
      align-self: center;

      .thumb {
        height: 2rem;
        width: 2rem;
        border-radius: $border-radius;
        background-color: $white-color;
        display: flex;
        justify-content: center;
        align-items: center;
        box-shadow: 0 0.2rem 0.6rem $datepicker-header-color;

        &:focus {
          outline: none;
        }

        &:hover {
          cursor: pointer;
        }
      }
    }
  }
}
