.mobile-nav {
    &.open {
        position: fixed;
        z-index: 1000;
        height: 100vh;
        width: 100vw;
        overflow: auto;
    }

    background-color: $primary-color;

    .content-main {
        flex: 1;
        display: flex;
        flex-direction: column;
        align-items: space-between;
        justify-content: center;

        .mobile-header {
            position: relative;
            display: flex;
            align-items: center;
            align-self: stretch;
            justify-content: space-between;
            width: $menu-width-mobile;
            height: $header-height-mobile;
            padding: 1rem 1.7rem;
            background: $mobile-header-gradient-bright;
            background: linear-gradient(45deg, $mobile-header-gradient-bright, $mobile-header-gradient-dark);
            .mobile-menu-toggle-button {
                display: flex;
                align-items: center;
                justify-content: center;
                padding: 1rem;
                color: $white-color;
                background-color: transparent;
                font-size: $ml-font-size;
                .feather {
                    font-weight: bold;
                }
            }
            .mobile-logo-wrapper {
                display: flex;
                height: 5rem;
                width: 15rem;
                align-items: center;
                background: url($logoImageBase64) no-repeat center;
                background-size: contain;
            }
        }
        .navbar-collapse {
            justify-content: flex-end;
            flex-basis: auto;
            flex-grow: 1;
            &.collapsed {
                display: none;
            }
            .navbar-nav {
                width: 100vw;
                padding: 0 0 2rem 0;
                flex-direction: column-reverse;
                align-items: stretch;
                justify-content: flex-start;
                background-color: $white-color;

                .navbar-item {
                    color: $black-color;
                    margin-right: 0;
                    padding: 1rem 2rem;
                    font-size: $base-ms-font-size;

                    &.usermenu-dropdown {
                        flex: 1;
                        flex-direction: column;
                        padding: 1rem 0;

                        .dropdown-menu-head {
                            padding: 1rem 2rem;
                            justify-content: space-between;
                            .username {
                                font-size: $ml-font-size;
                                font-weight: 600;
                            }
                            border-bottom: thin solid $border-color;

                        }
                        .dropdown-menu {
                            position: unset;
                            box-shadow: none;
                            border-bottom: thin solid $border-color;
                            padding: 0;
                            .dropdown-menu-head {
                                display: none;
                            }
                            .dropdown-menu-body {
                                .dropdown-item {
                                    padding: 1rem 2rem;
                                    font-size: $base-ms-font-size;
                                }
                            }
                        }
                    }
                    // .dropdown-menu {
                    // }
                }
            }
            .sidebar-menu {
                width: 100vw;
                list-style: none;
                background-color: $primary-color;
                border-top: thin solid $primary-color;
                .nav-item {

                    &:hover {
                        color: transparentize($theme-second-color, 0.9);
                        background: transparent;
                    }
                }
                .nav-link {
                    display: flex;
                    align-items: center;
                    color: $white-color;
                    font-size: 1.6rem;
                    font-family: Montserrat, sans-serif;
                    font-weight: 500;
                    position: relative;
                    transition: none;
                    padding: 1rem 2rem;
                    .nav-link-text {
                        overflow: hidden;
                        font-size: $base-font-size;
                    }

                    &.active {
                        font-weight: 600;
                        cursor: initial;
                        color: $black-color;
                        background-color: #fff;

                        position: relative;
                    }
                    &:not(.active):hover {
                        font-weight: 400;
                        color: $black-color;
                        cursor: pointer;
                    }
                    &:after {
                        content: "\e844";
                        font-family: "feather";
                        position: absolute;
                        top: 1.75rem;
                        right: 2rem;
                        transition: all 0.3s ease-in-out;
                    }
                }
            }
        }
    }
}
