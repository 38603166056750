.edit-profile-image-host {
  display: flex;

  &.visible {
    flex: 1;
    width: 100%;
  }

  .rs-uploader-file-items {
    display: flex;
    flex: 1;

    .rs-uploader-file-item {
      display: flex;
      flex: 1;
      justify-content: center;
      align-content: center;
      justify-items: center;
      align-items: center;

      .rs-uploader-file-item-panel {
        display: flex;
        flex: 1;

        .rs-uploader-file-item-content {
          display: flex;
          flex: 1;
        }
      }

      .rs-uploader-file-item-preview {
        display: flex;
        border-radius: 50%;
        overflow: hidden;
        height: 14rem; // 11.3rem;
        width: 14rem; // 11.3rem;
        border: thin solid $border-color;

        img {
          display: flex;
          width: 100%;
          object-fit: cover;
        }
      }
    }
  }

  .rs-uploader-trigger-customize {
    display: flex;
    position: absolute;
    bottom: -4rem;
    right: 0;
    left: 0;

    input {
      display: none;
    }

    .rs-uploader-trigger-btn {
      display: flex;
      flex: 1;
      justify-content: center;
      padding: 0.5rem;
      background-color: transparent;
      border: none;
      cursor: pointer;
      color: $info-color;
    }
  }
}

.edit-profile-image-button {
  position: absolute;
  bottom: 0;
  padding: 0.5rem;
  border-radius: $border-radius;
  opacity: 0.75;

  &:hover {
    opacity: 1;
  }

  &.accept {
    background-color: $success-color;
    right: 0;
  }

  &.remove {
    background-color: $danger-color;
    left: 0;
  }
}

