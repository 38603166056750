.quantity-input-group {
  display: flex;
  height: 2.5rem;
  padding: 0.4rem 0;
  border-radius: 0.2rem;
  border: 0.1rem solid $input-border-color;

  .button-minus,
  .button-plus {
    height: 100%;
    width: 2.1rem;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: $white-color;
    color: $input-border-color;
    font-size: $base-font-size;
  }

  .quantity-field {
    width: 4.5rem;
    height: 100%;
    padding: 0 1rem;
    outline: none;
    border: none;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: $base-font-size;
    color: $input-border-color;
    text-align: center;
    border-left: 0.1rem solid $input-border-color;
    border-right: 0.1rem solid $input-border-color;
    margin-left: 0;
  }
}
