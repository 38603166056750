.dashboard-view {
  height: 100vh;
  display: flex;
  flex-direction: column;

  .main-container-wrapper {
    flex: 1;
    display: flex;

    .main-container {
      flex: 1;
      display: flex;
      flex-direction: column;

      .content-wrapper {
        display: flex;
        flex-direction: column;
        align-items: stretch;
        justify-content: stretch;
        flex: 1;
        transition: all 0.3s ease-in-out;

        .content {
          display: flex;
          flex-direction: column;
          align-items: stretch;
          flex: 1;
          padding: 2.5rem 2.5rem 0 2.5rem;
          position: relative;

          .breadcrumbs {
            display: flex;
            margin-bottom: 2rem;
            padding: 0 3rem;
            font-weight: 600;

            .breadcrumb-list {
              display: flex;
              list-style: none;

              .breadcrumb-item {
                display: flex;
                justify-content: center;
                align-items: center;

                .breadcrumb-link {
                  display: flex;
                  justify-content: center;
                  align-items: center;
                  font-size: $small-font-size;
                  font-weight: 500;
                  line-height: 1.21;
                  letter-spacing: 0.6px;
                  color: $black-color;
                  text-decoration: none;

                  i {
                    font-size: $small-font-size;
                    margin-right: 1rem;
                  }
                }

                &:not(:first-child) {
                  &::before {
                    content: "\e844";
                    font-family: "feather";
                    font-size: $small-font-size;
                  }
                }

                &:hover {
                  a {
                    color: $info-color;
                  }
                }

                &:not(.item-active):last-of-type {
                  a {
                    pointer-events: none;
                    color: $black-color;
                  }
                }
              }
            }
          }

          .header-section {
            margin-bottom: 2.8rem;
            padding: 0 3rem;

            .header-title {
              display: flex;
              justify-content: flex-start;
              margin-bottom: 1rem;
              font-size: $xxxl-font-size;
              letter-spacing: 0.1rem;
              font-weight: 600;
              color: $black-color;
            }
            .header-description {
              letter-spacing: 1.5px;
              line-height: 1.8;
              color: $black-color;
              font-weight: 500;
            }
          }
        }
        .footer {
          font-family: Montserrat, sans-serif;
          font-size: $xs-font-size;
          font-weight: 500;
          line-height: 2.18;
          letter-spacing: 0.055rem;
          padding: 1.5rem 0 1.7rem 3.1rem;
          color: $gray-text-color;
        }
      }
    }
  }
}

@media only screen and (max-width: $breakpoint-medium) {
  .dashboard-view {
    .content-main {
      .main-container {
        margin-left: 0;

        &.collapsed {
          margin-left: $menu-width;
        }
      }
    }
  }
}
