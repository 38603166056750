.rs-uploader {
  .rs-uploader-trigger-customize {
    .rs-uploader-trigger-btn {
      padding: 1rem 1.5rem;

      i {
        font-size: $xl-font-size;
        color: #ccc;
      }

      p {
        font-weight: 500;
        font-size: $base-font-size;
        color: $view-title-color;

        span {
          color: $theme-color;
          text-decoration: underline;
        }
      }
    }
  }

  .rs-uploader-file-items {
    .rs-uploader-file-item.rs-uploader-file-item-picture-text {
      border: 1px dashed #e5e5ea;
      padding: 1rem 0;
      border-radius: 0;
      height: auto;
      display: flex;
      justify-content: center;
      align-items: center;

      .rs-uploader-file-item-preview {
        position: relative;
        border-radius: 0;
        height: 8rem;
        width: 8rem;
      }

      .rs-uploader-file-item-panel {
        .rs-uploader-file-item-content {
          color: $view-title-color;

          .rs-file-custom-content {
            p {
              margin-bottom: 0.5rem;
            }

            .rs-remove-file {
              color: $theme-color;
              background-color: transparent;
              display: flex;
              justify-content: space-between;
              align-items: center;
              font-weight: 500;
              font-size: $base-font-size;

              &:hover {
                opacity: 0.5;
              }

              span {
                text-decoration: underline;
                text-transform: lowercase;
                margin-left: 0.5rem;
              }
            }
          }

          .rs-uploader-file-item-size {
            display: none;
          }
        }
      }

      &:hover {
        background-color: transparent;
      }
    }
  }
}
