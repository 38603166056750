.rs-picker {
  display: flex;
  width: 100%;
  border-radius: $border-radius;
  border: 0.1rem solid $input-border-color;
  background: $white-color;
  color: $theme-second-color;
  font-size: $base-font-size;
  height: 5rem;
  padding: 0 0.8rem;

  .rs-picker-toggle.rs-btn {
    display: flex;
    flex: 1;
    z-index: 0;

    input {
      border: none;
    }
    .rs-picker-toggle-value,
    .rs-picker-toggle-placeholder {
      display: none;
    }

    .rs-picker-toggle-caret {
      top: 1.5rem;
    }

    .rs-picker-toggle-clean {
      top: 1.2rem;
    }
  }
}

.rs-picker-daterange-panel {
  border-radius: 0.4rem;

  .rs-picker-daterange-content {
    .rs-picker-daterange-calendar-group {
      .rs-calendar {
        .rs-calendar-view {
          .rs-calendar-table-cell {
            &.rs-calendar-table-cell-selected-end,
            &.rs-calendar-table-cell-selected-start {
              .rs-calendar-table-cell-content {
                background: $info-color;
              }
            }

            &.rs-calendar-table-cell-is-today {
              .rs-calendar-table-cell-content {
                border-color: $info-color;
              }
            }
          }
        }
      }
    }
  }

  .rs-picker-toolbar {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .rs-picker-toolbar-ranges, .rs-picker-toolbar-right {
      margin-top: 0;

      .rs-btn {
        background: transparent;
        padding: 0 0.5rem;
        color: $info-color;
        font-size: 1.4rem;
        font-weight: 500;
        font-family: Montserrat, sans-serif;
      }
    }
  }
}
