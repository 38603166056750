.user-img {
  .image-placeholder {
    width: 14rem;
    height: 14rem;
    border-radius: 50%;
    background-color: $theme-border;
    position: relative;
    box-shadow: 0.8rem 0.9rem 3.2rem 0 rgba(0, 0, 0, 0.1);

    .notification {
      position: absolute;
      width: 4rem;
      height: 4rem;
      box-shadow: 0.6rem 0.8rem 2.7rem 0 rgba(0, 0, 0, 0.1);
      background-color: $secondary-color;
      color: $white-color;
      border-radius: 50%;
      z-index: 99;
      font-family: Montserrat, sans-serif;
      font-size: $ml-font-size;
      font-weight: 500;
      text-align: center;
      display: flex;
      justify-content: center;
      align-items: center;
      top: 0;
      right: -0.5rem;
    }
  }
}

.mentor-img {
  position: relative;
  height: 14rem; // 11.3rem;
  width: 14rem; // 11.3rem;
  background-color: $input-border-color;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  font-size: 2.4rem;
  text-decoration: none;

  &.adaptive {
    height: 100%;
    width: 100%;
  }
}
